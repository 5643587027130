import { Link, useParams } from "react-router-dom";
import Table from "../../Components/Table";
import useTableControls from "../../Hooks/useTableControls";
import { useQuery } from "react-query";
import { getUserDetails } from "../../Services/User";
import { useEffect, useState } from "react";
import useFetchData from "../../Hooks/useFetchData";
import { getAllRides } from "../../Services/Ride";
import { format_date, getSerialNumber } from "../../Utils/helpers";
import BackButton from "../../Components/BackButton";

export default function MoverDetails() {
    const { id } = useParams();
    const vehicleId = 2;

    // const sampleData = [
    //   {
    //     sn: "1",
    //     bookingId: "123",
    //     date: "01/02/2022",

    //     pickupLocation: "Abc Location",
    //     dropoffLocation: "Abc Location",
    //     totalAmount: "123",
    //     adminCommission: "123",
    //     myShare: "123",
    //     status: "pending",
    //   },
    //   {
    //     sn: "2",
    //     bookingId: "123",
    //     date: "01/02/2022",

    //     pickupLocation: "Abc Location",
    //     dropoffLocation: "Abc Location",
    //     totalAmount: "123",
    //     adminCommission: "123",
    //     myShare: "123",
    //     status: "accepted",
    //   },
    //   {
    //     sn: "3",
    //     bookingId: "123",
    //     date: "01/02/2022",

    //     pickupLocation: "Abc Location",
    //     dropoffLocation: "Abc Location",
    //     totalAmount: "123",
    //     adminCommission: "123",
    //     myShare: "123",
    //     status: "completed",
    //   },
    //   {
    //     sn: "4",
    //     bookingId: "123",
    //     date: "01/02/2022",

    //     pickupLocation: "Abc Location",
    //     dropoffLocation: "Abc Location",
    //     totalAmount: "123",
    //     adminCommission: "123",
    //     myShare: "123",
    //     status: "cancelled",
    //   },

    //   {
    //     sn: "5",
    //     bookingId: "123",
    //     date: "01/02/2022",

    //     pickupLocation: "Abc Location",
    //     dropoffLocation: "Abc Location",
    //     totalAmount: "123",
    //     adminCommission: "123",
    //     myShare: "123",
    //     status: "ongoing",
    //   },
    // ];

    const [driver, setDriver] = useState({});
    const {
        perPage,
        setPerPage,
        status,
        setStatus,
        search_string,
        setSearchString,
        from,
        setFrom,
        to,
        setTo,
    } = useTableControls();

    const { data, isLoading, refetch } = useQuery(["driver_details", id], () =>
        getUserDetails(id)
    );
    console.log("driver_details: ", data?.data);

    useEffect(() => {
        if (data?.data?.data?.docs?.length > 0)
            setDriver(data?.data?.data?.docs?.[0]);
    }, [data?.data?.data?.docs]);



    const {
        // INTERNAL EXPORTS
        setPage,
        // REACT QUERY EXPORTS
        isFetching: rideFetching,
        isLoading: rideLoading,
        data: rideData,
        refetch: rideRefetch,
    } = useFetchData("driver_ride_logs", getAllRides, [
        perPage,
        search_string,
        status,
        from,
        to,
        null,
        id,
    ]);
    // console.log("driver_ride_logs Data: ", rideData?.data);

    return (
        <div className="app-content content dashboard">
            <div className="content-wrapper">
                <div className="content-body">
                    {/* Basic form layout section start */}
                    <section id="configuration">
                        <div className="row">
                            <div className="col-12">
                                <div className="card-content collapse show dashCard pt-5 px-5">
                                    <div className="row mb-4">
                                        <div className="col-12">
                                            <div className="back-title">
                                                <BackButton />
                                                <div className="pageTitleInner d-flex align-items-baseline">
                                                    <h1 className="pageTitle text-capitalize m-0">
                                                        Muvrrs Details
                                                    </h1>
                                                    {/* <span className="mx-2">
                            <Link
                              to="/mover-management"
                              className="backLink mr-1"
                            >
                              <i className="fas fa-chevron-left" />
                            </Link>
                            Muvrrs Details
                          </span> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="dashCard2 mb-5">
                                        <div className="py-4 px-4 border-bottom">
                                            <div className="d-block d-md-flex pb-3">
                                                <div className="userIimageFrame me-3">
                                                    <img
                                                        src={
                                                            driver?.profileImage?.imageUrl ??
                                                            "images/avatar.png"
                                                        }
                                                        alt=""
                                                        className="userImage img-fluid"
                                                    />
                                                </div>
                                                <div className="flex-grow-1 text-md-end">
                                                    <div className="statusBox">
                                                        <p className="m-0">
                                                            Status:{" "}
                                                            <span>
                                                                {driver?.active === true
                                                                    ? "Active"
                                                                    : driver?.active === false && "Inactive"}
                                                            </span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="user-details">
                                                <div className="mainDetail d-lg-flex align-items-baseline mt-md-3">
                                                    <label htmlFor="" className="mainLabel mb-0">
                                                        Full Name:
                                                    </label>
                                                    <p className="mainText mb-0">{driver?.firstName}</p>
                                                </div>
                                                <div className="mainDetail d-lg-flex align-items-baseline mt-md-3">
                                                    <label htmlFor="" className="mainLabel mb-0">
                                                        Email Address:
                                                    </label>
                                                    <p className="mainText mb-0">{driver?.email}</p>
                                                </div>
                                                <div className="mainDetail d-lg-flex align-items-baseline mt-md-3">
                                                    <label htmlFor="" className="mainLabel mb-0">Phone Number:</label>
                                                    <p className="mainText mb-0">{driver?.phone}</p>
                                                </div>
                                                <div className="mainDetail d-lg-flex align-items-baseline mt-md-3">
                                                    <label htmlFor="" className="mainLabel mb-0">Date:</label>
                                                    <p className="mainText mb-0">
                                                        {driver?.createdAt &&
                                                            format_date(driver?.createdAt, "DD/MM/YYYY")}
                                                    </p>
                                                </div>
                                                <div className="mainDetail d-lg-flex align-items-baseline mt-md-3">
                                                    <label htmlFor="" className="mainLabel mb-0">SSN:</label>
                                                    <p className="mainText mb-0">000-00-0000</p>
                                                </div>
                                                <div className="mainDetail d-lg-flex align-items-baseline mt-md-3">
                                                    <label htmlFor="" className="mainLabel mb-0">Total Bookings:</label>
                                                    <p className="mainText mb-0">{rideData?.data?.data?.totalDocs}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="py-4 px-4">
                                            <h1 class="pageTitle text-capitalize mb-3">Vehicle Details</h1>
                                            <div className="user-details">
                                                <div className="mainDetail d-xl-flex align-items-baseline mt-3">
                                                    <label htmlFor="" className="mainLabel mb-0">
                                                        License No.:
                                                    </label>
                                                    <p className="mainText mb-0">1241424</p>
                                                </div>
                                                <div className="mainDetail d-lg-flex align-items-start mt-3">
                                                    <label htmlFor="" className="mainLabel">
                                                        License:
                                                    </label>
                                                    <div className="licence-wrap d-flex flex-grow-1 gap-3 flex-xl-row flex-column">
                                                        <div className="licence-img">
                                                            <img
                                                                src={
                                                                    (driver?.licenseImageFront?.imageUrl) ??
                                                                    "images/license.png"
                                                                }
                                                                alt=""
                                                                className="img-fluid"
                                                            />
                                                        </div>
                                                        <div className="licence-img">
                                                            <img
                                                                src={
                                                                    (driver?.licenseImageBack?.imageUrl) ??
                                                                    "images/license.png"
                                                                }
                                                                alt=""
                                                                className="img-fluid"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-xl-10 col-xxl-8">
                                                        <div className="row">
                                                            <div className="col-md-6 mt-3">
                                                                <div className="mainDetail d-xl-flex align-items-baseline">
                                                                    <label htmlFor="" className="mainLabel mb-0">Vehicle Name:</label>
                                                                    <p className="mainText mb-0">{driver?.vehicle?.name}</p>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6 mt-3">
                                                                <div className="mainDetail d-xl-flex align-items-baseline">
                                                                    <label htmlFor="" className="mainLabel mb-0">GVWR :</label>
                                                                    <p className="mainText mb-0">2000 KG</p>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6 mt-3">
                                                                <div className="mainDetail d-xl-flex align-items-baseline">
                                                                    <label htmlFor="" className="mainLabel mb-0">Vehicle Type:</label>
                                                                    <p className="mainText mb-0">{driver?.vehicle?.type?.name}</p>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6 mt-3">
                                                                <div className="mainDetail d-xl-flex align-items-baseline">
                                                                    <label htmlFor="" className="mainLabel mb-0">Brand Name :</label>
                                                                    <p className="mainText mb-0">2000 KG</p>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6 mt-3">
                                                                <div className="mainDetail d-xl-flex align-items-baseline">
                                                                    <label htmlFor="" className="mainLabel mb-0">Vehicle Color:</label>
                                                                    <p className="mainText mb-0">{driver?.vehicle?.color}</p>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6 mt-3">
                                                                <div className="mainDetail d-xl-flex align-items-baseline">
                                                                    <label htmlFor="" className="mainLabel mb-0">Date:</label>
                                                                    <p className="mainText mb-0">
                                                                        {driver?.vehicle?.createdAt &&
                                                                            format_date(
                                                                                driver?.vehicle?.createdAt,
                                                                                "DD/MM/YYYY"
                                                                            )}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6 mt-3">
                                                                <div className="mainDetail d-xl-flex align-items-baseline">
                                                                    <label htmlFor="" className="mainLabel mb-0">Plate No:</label>
                                                                    <p className="mainText mb-0">{driver?.vehicle?.numberPlate}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row mt-3">
                                                            <div className="col-md-6 mt-2 troller-detail">
                                                                <div className="mainDetail d-xl-flex align-items-baseline">
                                                                    <label htmlFor="" className="mainLabel mb-0">Troller Size :</label>
                                                                    <p className="mainText mb-0">Medium</p>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6 mt-2 troller-detail">
                                                                <div className="mainDetail d-xl-flex align-items-baseline">
                                                                    <label htmlFor="" className="mainLabel mb-0">Specifications:</label>
                                                                    <p className="mainText mb-0">Medium</p>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6 mt-2 troller-detail">
                                                                <div className="mainDetail d-xl-flex align-items-baseline">
                                                                    <label htmlFor="" className="mainLabel mb-0">Accessories :</label>
                                                                    <p className="mainText mb-0">Tie Down Straps,</p>
                                                                    <p className="mainText mb-0">Appliances Dolly</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row mt-4">
                                                            <div className="col-xxl-10">
                                                                <div className="vehicle-wrap d-flex align-items-baseline flex-wrap my-1 gap-4">
                                                                    {
                                                                        driver?.vehicle?.vehicleImage?.map(item => (
                                                                            <div className="vehicle-img">
                                                                                <img
                                                                                    src={
                                                                                        item?.imageUrl ??
                                                                                        "images/truck1.png"
                                                                                    }
                                                                                    alt=""
                                                                                />
                                                                            </div>
                                                                        ))
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-12">
                                                                {/* <div className="mainDetail d-lg-flex align-items-start my-1">
    <label htmlFor="" className="mainLabel">
      Insurance:
    </label>
    <img
      src={
        (driver?.insurance?.imageUrl) ??
        "images/license.png"
      }
      alt=""
      className="mainImage img-fluid"
    />
  </div> */}
                                                                {/* <Link
                                      to={`/mover-management/mover-details/${id}/vehicle-details/${vehicleId}`}
                                      className="siteBtn mt-1"
                                    >
                                      Edit Vehicle Details
                                    </Link> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <Table
                                        key={"mover-details-booking-logs-table"}
                                        showTableName
                                        tableName={"Booking Logs"}
                                        tableTitles={[
                                            "S.No.",
                                            "Booking ID",
                                            "Date",
                                            "Pickup Location",
                                            "Drop-Off Location",
                                            "Total Amount",
                                            "Admin Commission",
                                            "My Share",
                                            "Status",
                                        ]}
                                        dateFilter
                                        showEntries
                                        sortBy
                                        isLoading={rideLoading}
                                        isFetching={rideFetching}
                                        data={rideData?.data?.data?.docs}
                                        totalPages={rideData?.data?.data?.totalPages}
                                        totalDocs={rideData?.data?.data?.totalDocs}
                                        setPage={setPage}
                                        setSearchString={setSearchString}
                                        status={status}
                                        setStatus={setStatus}
                                        perPage={perPage}
                                        setPerPage={setPerPage}
                                        from={from}
                                        setFrom={setFrom}
                                        to={to}
                                        setTo={setTo}
                                        status_options={[
                                            {
                                                label: "Pending",
                                                value: "pending",
                                            },
                                            {
                                                label: "Accepted",
                                                value: "accepted",
                                            },
                                            {
                                                label: "Rejected",
                                                value: "rejected",
                                            },
                                            {
                                                label: "Started",
                                                value: "started",
                                            },
                                            {
                                                label: "Completed",
                                                value: "completed",
                                            },
                                            {
                                                label: "Cancelled",
                                                value: "cancelled",
                                            },
                                        ]}
                                    >
                                        {!rideFetching &&
                                            rideData?.data?.data?.docs?.map((item, index) => (
                                                <tr>
                                                    <td>
                                                        {getSerialNumber(rideData?.data?.data, index)}
                                                    </td>
                                                    <td>{item?._id}</td>
                                                    <td>
                                                        {item?.createdAt &&
                                                            format_date(item?.createdAt, "MM/DD/YYYY")}
                                                    </td>
                                                    <td>{item?.bookingDetails?.pickupLocation?.name}</td>
                                                    <td>{item?.bookingDetails?.dropOffLocation?.name}</td>
                                                    <td>${item?.bookingDetails?.price?.toFixed(2)}</td>
                                                    <td>
                                                        {item.adminCommission && `$${item.adminCommission}`}
                                                    </td>
                                                    <td>{item.myShare && `$${item.myShare}`}</td>
                                                    <td>{item?.status}</td>
                                                </tr>
                                            ))}
                                    </Table>

                                    {/* <div className="row mb-4">
                    <div className="col-12">
                      <div className="back-title">
                        <div className="pageTitleInner">
                          <h1 className="pageTitle text-capitalize mb-0 mt-2">
                            Booking Logs
                          </h1>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-12">
                      <div className="dataTables_length text-start mb-4">
                        <div className="user-listing-top dashCard2 py-4 px-4">
                          <div className="d-xxl-flex align-items-end justify-content-between mb-xxl-3">
                            <div className="userInput mb-3">
                              <div className="d-md-flex align-items-center">
                                <label
                                  htmlFor=""
                                  className="dashLabel mb-3 mb-xxl-0 me-3"
                                >
                                  Sort By Date
                                </label>
                                <div className="d-sm-flex">
                                  <div className="mb-2 mb-xxl-0 me-sm-3">
                                    <input
                                      className="dashInput p-3"
                                      type="date"
                                    />
                                  </div>
                                  <div className="mb-2 mb-xxl-0">
                                    <input
                                      className="dashInput p-3"
                                      type="date"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="userInput mb-2">
                              <div className="form-field mb-3">
                                <div className="d-xxl-flex align-items-center justify-content-end">
                                  <div className="dataTables_filter">
                                    <div className="dashSearchWrap">
                                      <form action="" method="POST">
                                        <input
                                          type="search"
                                          id="search-inp"
                                          className="dashInput search-input w-100"
                                          placeholder="Search"
                                        />
                                        <button
                                          type="submit"
                                          className="search-icon"
                                        >
                                          <i className="fas fa-search" />
                                        </button>
                                      </form>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="d-xxl-flex align-items-end flex-wrap justify-content-between mb-xxl-3">
                            <div className="userInput mb-3">
                              <label className="dashLabel d-inline-block mb-3 mb-xxl-0 me-3">
                                Show
                              </label>
                              <select className="d-inline-block dashInput sm-dropdown">
                                <option value={10}>10</option>
                                <option value={25}>25</option>
                                <option value={50}>50</option>
                                <option value={100}>100</option>
                              </select>
                              <label className="dashLabel d-inline-block mb-3 mb-xxl-0 ms-3">
                                Entries
                              </label>
                            </div>
                            <div className="userInput mb-3">
                              <label className="dashLabel d-inline-block mb-3 mb-xxl-0">
                                Filter By:
                              </label>
                              <select className="d-inline-block dashInput">
                                <option
                                  value=""
                                  disabled=""
                                  hidden=""
                                  selected=""
                                >
                                  All
                                </option>
                                <option value={1}>Active</option>
                                <option value={2}>Inactive</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="maain-tabble table-responsive">
                        <table className="table table-bordered zero-configuration">
                          <thead>
                            <tr>
                              <th>S No.</th>
                              <th>Booking ID</th>
                              <th>Date</th>
                              <th>Pickup Location</th>
                              <th>Drop-Off Location</th>
                              <th>Total Amount</th>
                              <th>Admin Commission</th>
                              <th>My Share</th>
                              <th>Status</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>1</td>
                              <td>123</td>
                              <td>mm/dd/yyyy</td>
                              <td>Abc Location</td>
                              <td>Abc Location</td>
                              <td>$123</td>
                              <td>$123</td>
                              <td>$123</td>
                              <td>Pending</td>
                            </tr>
                            <tr>
                              <td>2</td>
                              <td>123</td>
                              <td>mm/dd/yyyy</td>
                              <td>Abc Location</td>
                              <td>Abc Location</td>
                              <td>$123</td>
                              <td>$123</td>
                              <td>$123</td>
                              <td>On-going</td>
                            </tr>
                            <tr>
                              <td>3</td>
                              <td>123</td>
                              <td>mm/dd/yyyy</td>
                              <td>Abc Location</td>
                              <td>Abc Location</td>
                              <td>$123</td>
                              <td>$123</td>
                              <td>$123</td>
                              <td>Completed</td>
                            </tr>
                            <tr>
                              <td>4</td>
                              <td>123</td>
                              <td>mm/dd/yyyy</td>
                              <td>Abc Location</td>
                              <td>Abc Location</td>
                              <td>$123</td>
                              <td>$123</td>
                              <td>$123</td>
                              <td>Cancelled</td>
                            </tr>
                            <tr>
                              <td>5</td>
                              <td>123</td>
                              <td>mm/dd/yyyy</td>
                              <td>Abc Location</td>
                              <td>Abc Location</td>
                              <td>$123</td>
                              <td>$123</td>
                              <td>$123</td>
                              <td>Accepted</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div className="row align-items-center">
                    <div className="col-sm-12 col-md-5">
                      <div
                        className="showing-result"
                        id="DataTables_Table_0_info"
                      >
                        Showing 10 of 50 Entries
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-7">
                      <div
                        className="dataTables_paginate paging_simple_numbers"
                        id="DataTables_Table_0_paginate"
                      >
                        <ul className="pagination justify-content-end">
                          <li className="paginate_button page-item previous">
                            <button type="button" className="page-link notBtn">
                              Previous
                            </button>
                          </li>
                          <li className="paginate_button page-item active">
                            <button type="button" className="page-link notBtn">
                              01
                            </button>
                          </li>
                          <li className="paginate_button page-item">
                            <button type="button" className="page-link notBtn">
                              02
                            </button>
                          </li>
                          <li className="paginate_button page-item">
                            <button type="button" className="page-link notBtn">
                              03
                            </button>
                          </li>
                          <li className="paginate_button page-item next">
                            <button type="button" className="page-link notBtn">
                              Next
                            </button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div> */}
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    );
}
