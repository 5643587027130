import { createSlice } from "@reduxjs/toolkit";
import initialState from "../Redux/state";

export const slice = createSlice({
  name: "slice",
  initialState: initialState,
  reducers: {
    setUser: (state, action) => {
      state = { ...state, user: action.payload };
      return state;
    },
  },
});

// this is for dispatch
export const { setUser } = slice.actions;

// this is for configureStore
export default slice.reducer;
