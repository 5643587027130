import Table from "../../Components/Table";
import StatusHandler from "../../Components/Table/StatusHandler";
import { Confirmation, Success, Error } from "../../Components/Modal";
import { Link } from "react-router-dom";
import useTableControls from "../../Hooks/useTableControls";
import useFetchData from "../../Hooks/useFetchData";
import { changeUserStatus, getAllUsers } from "../../Services/User";
import { useMutation } from "react-query";
import { format_date, getSerialNumber } from "../../Utils/helpers";

export default function BusinessManagement() {
    // const sampleData = [
    //   {
    //     sn: "1",
    //     src: "images/avatar2.png",
    //     name: "Mark Carson",
    //     email: "abc@xyz.com",
    //     registeredOn: "01/02/2022",
    //     status: true,
    //   },
    //   {
    //     sn: "2",
    //     src: "images/avatar2.png",
    //     name: "Mark Carson",
    //     email: "abc@xyz.com",
    //     registeredOn: "01/02/2022",
    //     status: false,
    //   },
    //   {
    //     sn: "3",
    //     src: "images/avatar2.png",
    //     name: "Mark Carson",
    //     email: "abc@xyz.com",
    //     registeredOn: "01/02/2022",
    //     status: true,
    //   },
    //   {
    //     sn: "4",
    //     src: "images/avatar2.png",
    //     name: "Mark Carson",
    //     email: "abc@xyz.com",
    //     registeredOn: "01/02/2022",
    //     status: true,
    //   },
    // ];

    const {
        perPage,
        setPerPage,
        status,
        setStatus,
        search_string,
        setSearchString,
        from,
        setFrom,
        to,
        setTo,
    } = useTableControls();

    const {
        // INTERNAL EXPORTS
        setPage,
        // REACT QUERY EXPORTS
        isFetching,
        isLoading,
        data,
        refetch,
    } = useFetchData("driver_logs", getAllUsers, [
        perPage,
        "business",
        search_string,
        status,
        from,
        to,
        true
    ]);
    // console.log("driver_logs Data: ", data?.data);

    const { mutate, isLoading: loadingStatus } = useMutation(
        (id) => changeUserStatus(id),
        {
            onSuccess: (res) => {
                refetch();
                Success("Muvrrs Status", res.data?.message);
            },
            onError: (err) => {
                Error(err?.response?.data?.message);
            },
        }
    );

    return (
        <div className="app-content content dashboard">
            <div className="content-wrapper">
                <div className="content-body">
                    {/* Basic form layout section start */}
                    <section id="configuration">
                        <div className="row">
                            <div className="col-12">
                                <div className="card-content collapse show dashCard pt-5 px-5">
                                    <div className="row mb-4">
                                        <div className="col-xl-6">
                                            <div className="back-title">
                                                <div className="pageTitleInner">
                                                    <h1 className="pageTitle text-capitalize mb-0 mt-1">
                                                        Business Management
                                                    </h1>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-6">
                                            <div className="text-end">
                                                <Link
                                                    to="/business-management/business-request"
                                                    className="siteBtn mt-1"
                                                >
                                                    Requests
                                                </Link>
                                            </div>
                                        </div>
                                    </div>

                                    <Table
                                        key={"mover-management-table"}
                                        tableTitles={[
                                            "S.No.",
                                            "Business Name",
                                            "Email Address",
                                            "Date",
                                            "Status",
                                            "Action",
                                        ]}
                                        dateFilter
                                        showEntries
                                        sortBy
                                        isLoading={isLoading}
                                        isFetching={isFetching}
                                        data={data?.data?.data?.docs}
                                        totalPages={data?.data?.data?.totalPages}
                                        totalDocs={data?.data?.data?.totalDocs}
                                        setPage={setPage}
                                        setSearchString={setSearchString}
                                        status={status}
                                        setStatus={setStatus}
                                        perPage={perPage}
                                        setPerPage={setPerPage}
                                        from={from}
                                        setFrom={setFrom}
                                        to={to}
                                        setTo={setTo}
                                    >
                                        {!isFetching &&
                                            data?.data?.data?.docs?.map((item, index) => (
                                                <tr>
                                                    <td>{getSerialNumber(data?.data?.data, index)}</td>
                                                    <td>{item?.companyName}</td>
                                                    <td>{item?.email}</td>
                                                    <td>
                                                        {item?.createdAt &&
                                                            format_date(item?.createdAt, "MM/DD/YYYY")}
                                                    </td>
                                                    <td>
                                                        <span className={`status-tag ${item.active ? 'green-tag' : 'red-tag'}`}
                                                        >
                                                            {item.active ? "Active" : "Inactive"}
                                                        </span>

                                                    </td>
                                                    <td>
                                                        <StatusHandler
                                                            key={`${item?._id}-status`}
                                                            view
                                                            active
                                                            item={item}
                                                            isLoading={loadingStatus}
                                                            redirectUrl={`/business-management/business-details/${item?._id}`}
                                                            onClick={() =>
                                                                Confirmation(
                                                                    `Are you sure you want to ${item?.active ? "inactive" : "active"
                                                                    }  ${item.firstName}`,
                                                                    "Yes",
                                                                    () => mutate(item?._id)
                                                                )
                                                            }
                                                        />
                                                    </td>
                                                </tr>
                                            ))}
                                    </Table>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    );
}
