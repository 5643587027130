import moment from "moment";
import useFetchData from "../../Hooks/useFetchData";
import { getNotifications } from "../../Services/Notification";
import Button from "../../Components/Button";

export default function Notifications() {
  const {
    // INTERNAL EXPORTS
    setPage,
    // REACT QUERY EXPORTS
    isFetching,
    isLoading,
    data,
    refetch,
  } = useFetchData("get-notifications", getNotifications);
  // console.log("get-notifications Data: ", data?.data);

  return (
    <div className="app-content content dashboard">
      <div className="content-wrapper">
        <div className="content-body">
          {/* Basic form layout section start */}
          <section id="configuration">
            <div className="row">
              <div className="col-12">
                <div className="card-content collapse show dashCard pt-5 px-5">
                  <div className="row mb-4">
                    <div className="col-xl-6">
                      <div className="back-title">
                        <div className="pageTitleInner d-flex align-items-baseline">
                          <h1 className="pageTitle text-capitalize m-0 mt-1">
                            Notifications
                          </h1>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row py-4 px-4 mb-3">
                    <div className="col-12">
                      <div className="row mb-4">
                        <div className="col-12">
                        <div class="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center gap-3 mb-3 mb-lg-4 col-12">
                          <div class="notification-filter d-flex">
                            <label class="fw-regular text-capitalize align-self-center me-3 " for="showNotifications">Showing:</label>
                            <div class="select-wrapper d-block mt-1 mt-sm-0  ">
                              <select aria-label="Default select example" name="showNotifications" class="form-select">
                                <option value="all">All</option>
                            <option value="new">New</option>
                            </select>
                            </div>
                            </div>
                            <div class="pe-4">
                              <button type="button" class="readBtn">Mark All as read</button>
                              </div>
                              </div>

                        </div>
                        <div className="col-xxl-12 col-xl-12">
                          <div className="dashCard2 py-3 px-4">
                            {data?.data?.data?.docs?.length > 0 ? (
                              data?.data?.data?.docs?.map((item) => (
                                <div className="notificationBox py-4">
                                  {/* <div className="d-flex gap-3 readBox unReadBox"> */}
                                  <div className="d-flex gap-3 unReadBox">
                                    <div className="notiImg">
                                      <i className="fas fa-bell mainNotiIcon" />
                                    </div>
                                    <div className="d-flex justify-content-between align-items-center flex-fill">
                                      <div className="notiContent px-2 flex-grow-1">
                                        <p className="notificationText mb-1">
                                          {item?.notification?.body}
                                        </p>
                                        <div className="notificationBelowInfo text-right d-flex">
                                          <small className="align-self-center">
                                            <time
                                              className="media-meta"
                                              // dateTime=""
                                            >
                                              {moment(
                                                item?.createdAt
                                              ).fromNow()}
                                            </time>
                                          </small>
                                        <Button className="readBtn ms-auto">Mark As Read</Button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))
                            ) : (
                              <div className="notificationBox py-4">
                                <div className="d-flex gap-3">
                                  <div className="notiContent px-2">
                                    <p className="notificationText mb-1">
                                      No new notification
                                    </p>
                                  </div>
                                </div>
                              </div>
                            )}
                            {/* <div className="notificationBox py-4">
                              <div className="d-flex gap-3">
                                <div className="notiImg">
                                  <i className="fas fa-bell mainNotiIcon" />
                                </div>
                                <div className="notiContent px-2">
                                  <p className="notificationText mb-1">
                                    Lorem ipsum dolor sit amet, consectetur
                                    adipis ear elit sed do eiusmod.tempor
                                    incididunt. labore dolore magna aliqua. Ut
                                    enim ad minim veniam quis nostrud
                                    exercitation ullamco laboris nisi ut aliquip
                                    ex ea commodo consequat.
                                  </p>
                                  <div className="notificationBelowInfo text-right">
                                    <small>
                                      <time className="media-meta" dateTime="">
                                        5 min ago
                                      </time>
                                    </small>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="notificationBox py-4">
                              <div className="d-flex gap-3">
                                <div className="notiImg">
                                  <i className="fas fa-bell mainNotiIcon" />
                                </div>
                                <div className="notiContent px-2">
                                  <p className="notificationText mb-1">
                                    Lorem ipsum dolor sit amet, consectetur
                                    adipis ear elit sed do eiusmod.tempor
                                    incididunt. labore dolore magna aliqua. Ut
                                    enim ad minim veniam quis nostrud
                                    exercitation ullamco laboris nisi ut aliquip
                                    ex ea commodo consequat.
                                  </p>
                                  <div className="notificationBelowInfo text-right">
                                    <small>
                                      <time className="media-meta" dateTime="">
                                        5 min ago
                                      </time>
                                    </small>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="notificationBox py-4">
                              <div className="d-flex gap-3">
                                <div className="notiImg">
                                  <i className="fas fa-bell mainNotiIcon" />
                                </div>
                                <div className="notiContent px-2">
                                  <p className="notificationText mb-1">
                                    Lorem ipsum dolor sit amet, consectetur
                                    adipis ear elit sed do eiusmod.tempor
                                    incididunt. labore dolore magna aliqua. Ut
                                    enim ad minim veniam quis nostrud
                                    exercitation ullamco laboris nisi ut aliquip
                                    ex ea commodo consequat.
                                  </p>
                                  <div className="notificationBelowInfo text-right">
                                    <small>
                                      <time className="media-meta" dateTime="">
                                        5 min ago
                                      </time>
                                    </small>
                                  </div>
                                </div>
                              </div>
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}
