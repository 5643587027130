import api from "../Utils/axios";

export const ImageWrapper = (userId, image, currentImageUrl, callback) => {
  const imageExists = [...image?.values()]?.[0];
  if (imageExists) {
    api
      .post(`/images/upload-single-image/${userId}`, image)
      .then((res) => {
        if (res?.data?.image) {
          callback(res?.data?.image);
        }
      })
      .catch((err) => {
        console.log("Image Upload Error: ", err);
      });
    return;
  }
  return callback(currentImageUrl);
};
