import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { forgetPasswordSchema } from "../../Schema/authSchema";
import { useMutation } from "react-query";
import { recoverPassword } from "../../Services/Auth";
import { Error, Success } from "../../Components/Modal";
import Button from "../../Components/Button";

export default function ForgotPassword() {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(forgetPasswordSchema),
  });

  const { mutate, isLoading } = useMutation((data) => recoverPassword(data), {
    retry: false,
    onSuccess: (res) => {
      // console.log("Recover: ", res.data);
      Success("Forgot Password", res.data?.message);
      navigate("/verification", { replace: true });
    },
    onError: (err) => {
      // console.log("recoverPassword Err: ", err);
      Error(err.response.data?.message);
    },
  });

  const submit = (data) => {
    // console.log(data, "data");
    localStorage.setItem("email", data?.email);
    mutate(data);
  };

  console.log(errors);

  return (
    <section className="loginPage">
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-lg-6 px-4 loginBgLeft d-none d-lg-block">
            <div className="loginLeftContent mb-3">
              <img
                src="images/loginLogo.png"
                alt=""
                className="img-fluid loginLogo"
              />
              {/* <p>
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                diam nonumy eirmod tempor invidunt ut labore et dolore magna
                aliquyam erat, sed diam voluptua. At vero eos et accusam et
                justo duo dolores et ea rebum.
              </p> */}
            </div>
            <img src="images/loginLeftImage.png" alt="" className="img-fluid" />
          </div>
          <div className="col-lg-6 loginBgRight">
            <div className="loginCard">
              <div className="text-center d-block d-lg-none">
                <img
                  src="images/loginLogo.png"
                  alt=""
                  className="loginLogo img-fluid mb-2"
                />
              </div>
              <div className="formBox">
                <div className="formHeading my-5">
                  <h2>Password Recovery</h2>
                </div>
                <form
                  // action="password-recovery-2.php"
                  // method="POST"
                  className="py-2"
                  onSubmit={handleSubmit(submit)}
                >
                  <div className="form-field">
                    <label htmlFor="" className="siteLabel">
                      Email<span className="text-danger">*</span>
                    </label>
                    <div className="position-relative">
                      <input
                        type="email"
                        className="siteInput"
                        placeholder="Enter Email Address"
                        name=""
                        id=""
                        {...register("email")}
                      />
                      <span className="text-danger">
                        {errors?.email?.message}
                      </span>
                    </div>
                  </div>
                  <div className="form-field mt-3 mt-lg-5 text-center">
                    <Button
                      type="submit"
                      className="siteBtn w-100 mb-3"
                      loading={isLoading}
                    >
                      Continue
                    </Button>
                    <Link
                      to="/"
                      className="siteBtn2 w-100 mb-3"
                      onClick={() => localStorage.removeItem("email")}
                    >
                      Back To Sign In
                    </Link>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
