import { Link, useParams, useNavigate } from "react-router-dom";
import Table from "../../Components/Table";
import useTableControls from "../../Hooks/useTableControls";
import { useMutation, useQuery } from "react-query";

import { changeUserStatus, getAllUsers, getUserDetails } from "../../Services/User";
import { useEffect, useState } from "react";
import useFetchData from "../../Hooks/useFetchData";
import { getAllRides } from "../../Services/Ride";
import { format_date, getSerialNumber } from "../../Utils/helpers";
import BackButton from "../../Components/BackButton";
import { Confirmation, Reason, Success } from "../../Components/Modal";
import StatusHandler from "../../Components/Table/StatusHandler";
import Button from "../../Components/Button";
import {
    changeSignupRequestStatus,
    getBusinessRequestDetails,
} from "../../Services/SignupRequest";

export default function BusinessRequestsDetails() {
    // const { id } = useParams();

    // console.log(id)
    // const [orderData, setOrderData] = useState([]);
    // useEffect(() =>{
    //   buyerManageData.forEach(item => {
    //         if(id == item.id){
    //         setOrderData(item);
    //         } 
    //     });
    // })



    // const vehicleId = 2;

    // const [driver, setDriver] = useState({});

    // const { data, isLoading, refetch } = useQuery(["driver_details", id], () =>
    //   getUserDetails(id)
    // );

    // console.log("driver_details: ", data?.data);







    // const {
    //     perPage,
    //     setPerPage,
    //     status,
    //     setStatus,
    //     search_string,
    //     setSearchString,
    //     from,
    //     setFrom,
    //     to,
    //     setTo,
    // } = useTableControls();

    // const {
    //     // INTERNAL EXPORTS
    //     setPage,
    //     // REACT QUERY EXPORTS
    //     isFetching,
    //     isLoading,
    //     data,
    //     refetch,
    // } = useFetchData("driver_logs", getAllUsers, [
    //     perPage,
    //     "driver",
    //     search_string,
    //     status,
    //     from,
    //     to,
    //     true
    // ]);
    // // console.log("driver_logs Data: ", data?.data);

    // const { mutate, isLoading: loadingStatus } = useMutation(
    //     (id) => changeUserStatus(id),
    //     {
    //         onSuccess: (res) => {
    //             refetch();
    //             Success("Muvrrs Status", res.data?.message);
    //         },
    //         onError: (err) => {
    //             Error(err?.response?.data?.message);
    //         },
    //     }
    // );

    // useEffect(() => {
    //     getUserDetails(id)
    //     if (data?.data?.data?.docs?.length > 0)
    //         setDriver(data?.data?.data?.docs?.[0]);
    // }, [data?.data?.data?.docs]);

    // console.log(data?.data?.data);

    const navigate = useNavigate();
    const { id } = useParams();
    const [requestDetail, setRequestDetail] = useState({});

    const { data, isLoading, refetch } = useQuery(
        ["signupRequest_details", id],
        () => getBusinessRequestDetails(id)
    );

    useEffect(() => {
        if (data?.data?.data)
            setRequestDetail(data?.data?.data);
    }, [data]);

    const { mutate, isLoading: loadingStatus } = useMutation(
        (data) => changeSignupRequestStatus(id, data),
        {
            onSuccess: (res) => {
                refetch();
                Success("Signup Request Status", res.data?.message);
            },
            onError: (err) => {
                Error(err?.response?.data?.message);
            },
        }
    );

    console.log(requestDetail);

    return (
        <div className="app-content content dashboard business-details">
            <div className="content-wrapper">
                <div className="content-body">
                    {/* Basic form layout section start */}
                    <section id="configuration">
                        <div className="row">
                            <div className="col-12">
                                <div className="card-content collapse show dashCard pt-5 px-5">
                                    <div className="row mb-4">
                                        <div className="col-12">
                                            <div className="back-title">
                                                <BackButton />
                                                <div className="pageTitleInner d-flex align-items-baseline">
                                                    <h1 className="pageTitle text-capitalize m-0">
                                                        Business Requests Details
                                                    </h1>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="dashCard2 mb-5">
                                        <div className="d-lg-flex py-4 px-4 border-bottom">
                                            <div className="me-4 me-xxl-5 pe-xxl-3">
                                                <div className="userIimageFrame my-2 ">
                                                    <img
                                                        src={
                                                            requestDetail?.user?.profileImage?.imageUrl ??
                                                            "images/avatar.png"
                                                        }
                                                        alt=""
                                                        className="userImage img-fluid"
                                                    />
                                                </div>
                                            </div>
                                            <div className="flex-grow-1">
                                                <div className="text-lg-end">
                                                    <div className="statusBox">
                                                        <p className="m-0">
                                                            Status:{" "}
                                                            <span>
                                                                {requestDetail?.user?.active === true
                                                                    ? "Active"
                                                                    : requestDetail?.user?.active === false && "Inactive"}
                                                            </span>
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="mt-2 pb-3 pb-lg-4 pb-xxl-4">
                                                    <div className="mainDetail d-lg-flex align-items-baseline my-1">
                                                        <label htmlFor="" className="mainLabel">
                                                            Company Name
                                                        </label>
                                                        <p className="mainText">{requestDetail?.user?.companyName}</p>
                                                    </div>
                                                    <div className="mainDetail d-lg-flex align-items-baseline my-1">
                                                        <label htmlFor="" className="mainLabel">
                                                            Email Address:
                                                        </label>
                                                        <p className="mainText">{requestDetail?.user?.email}</p>
                                                    </div>
                                                    <div className="mainDetail d-lg-flex align-items-baseline my-1">
                                                        <label htmlFor="" className="mainLabel">
                                                            Phone Number:
                                                        </label>
                                                        <p className="mainText">+ {requestDetail?.user?.countryCode} {requestDetail?.user?.phone}</p>
                                                    </div>
                                                    <div className="mainDetail d-lg-flex align-items-baseline my-1">
                                                        <label htmlFor="" className="mainLabel">
                                                            Company Address I
                                                        </label>
                                                        <p className="mainText">{requestDetail?.user?.companyAddress}</p>
                                                    </div>
                                                    <div className="mainDetail d-lg-flex align-items-baseline my-1">
                                                        <label htmlFor="" className="mainLabel">Google Pin Location</label>
                                                        <p className="mainText">{requestDetail?.user?.companyLocation}</p>
                                                    </div>
                                                    <div className="mainDetail d-lg-flex align-items-baseline my-1">
                                                        <label htmlFor="" className="mainLabel">Company Description</label>
                                                        <p className="mainText">{requestDetail?.user?.companyDescription}</p>
                                                    </div>
                                                    <div className="mainDetail d-lg-flex align-items-baseline my-1">
                                                        <label htmlFor="" className="mainLabel">EIN Number</label>
                                                        <p className="mainText">{requestDetail?.user?.einNumber}</p>
                                                    </div>
                                                    <div className="mainDetail d-lg-flex align-items-baseline my-1">
                                                        <label htmlFor="" className="mainLabel">Documents</label>
                                                        <p className="mainText">abc.pdf</p>
                                                    </div>
                                                    <div className="mainDetail d-lg-flex align-items-baseline my-1">
                                                        <label htmlFor="" className="mainLabel">No. of Vehicles That Mover Owns</label>
                                                        <p className="mainText">{requestDetail?.vehicles?.length}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {requestDetail?.vehicles?.map((vehicle) =>
                                            <div className="py-4 px-4 border-bottom">
                                                {/* <h5 class="text-capitalize mb-4 mt-3">No. of Vehicles That Mover Owns: <span className="ms-4">02</span></h5> */}
                                                <h1 class="pageTitle text-capitalize mb-3">Vehicle Details</h1>
                                                <div className="user-details mb-3">
                                                    <div className="row">
                                                        <div className="col-xl-10 col-xxl-8">
                                                            <div className="row">
                                                                <div className="col-md-6 mt-3">
                                                                    <div className="mainDetail d-xl-flex align-items-baseline">
                                                                        <label htmlFor="" className="mainLabel mb-0">Vehicle Name:</label>
                                                                        <p className="mainText mb-0">{vehicle?.name}</p>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-6 mt-3">
                                                                    <div className="mainDetail d-xl-flex align-items-baseline">
                                                                        <label htmlFor="" className="mainLabel mb-0">Brand Name :</label>
                                                                        <p className="mainText mb-0">{vehicle?.brandName}</p>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-6 mt-3">
                                                                    <div className="mainDetail d-xl-flex align-items-baseline">
                                                                        <label htmlFor="" className="mainLabel mb-0">Vehicle Type:</label>
                                                                        <p className="mainText mb-0">{vehicle?.type?.name}</p>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-6 mt-3">
                                                                    <div className="mainDetail d-xl-flex align-items-baseline">
                                                                        <label htmlFor="" className="mainLabel mb-0">Vehicle Color:</label>
                                                                        <p className="mainText mb-0">{vehicle?.color}</p>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-6 mt-3">
                                                                    <div className="mainDetail d-xl-flex align-items-baseline">
                                                                        <label htmlFor="" className="mainLabel mb-0">Troller Size:</label>
                                                                        <p className="mainText mb-0">{vehicle?.trollerSize}</p>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-6 mt-3">
                                                                    <div className="mainDetail d-xl-flex align-items-baseline">
                                                                        <label htmlFor="" className="mainLabel mb-0">Plate No:</label>
                                                                        <p className="mainText mb-0">{vehicle?.numberPlate}</p>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-6 mt-3">
                                                                    <div className="mainDetail d-xl-flex align-items-baseline">
                                                                        <label htmlFor="" className="mainLabel mb-0">Specifications:</label>
                                                                        <p className="mainText mb-0">{vehicle?.specification}</p>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6 mt-3">
                                                                    <div className="mainDetail d-xl-flex align-items-baseline">
                                                                        <label htmlFor="" className="mainLabel mb-0">GVWR :</label>
                                                                        <p className="mainText mb-0">{vehicle?.gvwr}</p>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6 mt-3">
                                                                    <div className="mainDetail d-xl-flex align-items-baseline">
                                                                        <label htmlFor="" className="mainLabel mb-0">Accessories:</label>
                                                                        <p className="mainText mb-0">{vehicle?.accessories}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row mt-4">
                                                                <div className="col-xxl-10">
                                                                    <div className="vehicle-wrap d-flex align-items-baseline flex-wrap my-1 gap-4">
                                                                        {
                                                                            vehicle?.vehicleImage?.map(item => (
                                                                                <div className="vehicle-img">
                                                                                    <img
                                                                                        src={
                                                                                            item?.imageUrl ??
                                                                                            "images/truck1.png"
                                                                                        }
                                                                                        alt=""
                                                                                    />
                                                                                </div>
                                                                            ))
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        )}
                                        {/* <div className="py-4 px-4">
                                            <h1 class="pageTitle text-capitalize mb-3">Vehicle Details</h1>
                                            <div className="user-details">
                                                <div className="row">
                                                    <div className="col-xl-10 col-xxl-8">
                                                        <div className="row">
                                                            <div className="col-md-6 mt-3">
                                                                <div className="mainDetail d-xl-flex align-items-baseline">
                                                                    <label htmlFor="" className="mainLabel mb-0">Vehicle Name:</label>
                                                                    <p className="mainText mb-0">{driver?.vehicle?.name}</p>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6 mt-3">
                                                                <div className="mainDetail d-xl-flex align-items-baseline">
                                                                    <label htmlFor="" className="mainLabel mb-0">Brand Name :</label>
                                                                    <p className="mainText mb-0">2000 KG</p>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6 mt-3">
                                                                <div className="mainDetail d-xl-flex align-items-baseline">
                                                                    <label htmlFor="" className="mainLabel mb-0">Vehicle Type:</label>
                                                                    <p className="mainText mb-0">{driver?.vehicle?.type?.name}</p>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6 mt-3">
                                                                <div className="mainDetail d-xl-flex align-items-baseline">
                                                                    <label htmlFor="" className="mainLabel mb-0">Vehicle Color:</label>
                                                                    <p className="mainText mb-0">{driver?.vehicle?.color}</p>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6 mt-3">
                                                                <div className="mainDetail d-xl-flex align-items-baseline">
                                                                    <label htmlFor="" className="mainLabel mb-0">Troller Size:</label>
                                                                    <p className="mainText mb-0">Medium Trailer</p>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6 mt-3">
                                                                <div className="mainDetail d-xl-flex align-items-baseline">
                                                                    <label htmlFor="" className="mainLabel mb-0">Plate No:</label>
                                                                    <p className="mainText mb-0">{driver?.vehicle?.numberPlate}</p>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6 mt-3">
                                                                <div className="mainDetail d-xl-flex align-items-baseline">
                                                                    <label htmlFor="" className="mainLabel mb-0">Specifications:</label>
                                                                    <p className="mainText mb-0">Open</p>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6 mt-3">
                                                                <div className="mainDetail d-xl-flex align-items-baseline">
                                                                    <label htmlFor="" className="mainLabel mb-0">GVWR :</label>
                                                                    <p className="mainText mb-0">2000 KG</p>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6 mt-3">
                                                                <div className="mainDetail d-xl-flex align-items-baseline">
                                                                    <label htmlFor="" className="mainLabel mb-0">Accessories:</label>
                                                                    <p className="mainText mb-0">Tie Down Straps</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row mt-4">
                                                            <div className="col-xxl-10">
                                                                <div className="vehicle-wrap d-flex align-items-baseline flex-wrap my-1 gap-4">
                                                                    {
                                                                        driver?.vehicle?.vehicleImage?.map(item => (
                                                                            <div className="vehicle-img">
                                                                                <img
                                                                                    src={
                                                                                        item?.imageUrl ??
                                                                                        "images/truck1.png"
                                                                                    }
                                                                                    alt=""
                                                                                />
                                                                            </div>
                                                                        ))
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}
                                        <div className="py-4 px-4 d-flex gap-3">
                                            {requestDetail?.status == "pending" && (
                                                <>
                                                    <Button
                                                        type="button"
                                                        className="greenBtn ms-1 mt-1"
                                                        loading={loadingStatus}
                                                        onClick={() => {
                                                            Confirmation(
                                                                `Are You Sure You Want To Approve The Signup Request ?`,
                                                                "Yes",
                                                                () => mutate({ status: "approved" })
                                                            );
                                                        }}
                                                    // data-bs-toggle="modal"
                                                    // data-bs-target="#approveSignupRequest"
                                                    >
                                                        Approve
                                                    </Button>
                                                    <Button
                                                        type="button"
                                                        className="redBtn ms-1 mt-1"
                                                        loading={loadingStatus}
                                                        onClick={() => {
                                                            Reason(
                                                                "Are you sure you want to reject the signup request ?",
                                                                "Yes",
                                                                (val) =>
                                                                    mutate({
                                                                        status: "rejected",
                                                                        reason: val,
                                                                    }),
                                                                "Please enter your rejection reason"
                                                            );
                                                        }}
                                                    // data-bs-toggle="modal"
                                                    // data-bs-target="#approveSignupRequest"
                                                    >
                                                        Reject
                                                    </Button>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    );
}
