import api from "../Utils/axios";
import { filterParams } from "../Utils/helpers";

export const getVehicleTypes = (
  page,
  perPage,
  search_string,
  status,
  from,
  to
) => {
  return api.get(`/vehicle-type/get`, {
    params: filterParams({
      page,
      limit: perPage,
      keyword: search_string,
      active: status,
      "createdAt[from]": from,
      "createdAt[to]": to,
    }),
  });
};

export const getVehicleTypeDetails = (id) => {
  return api.get(`/vehicle-type/get`, {
    params: {
      _id: id,
    },
  });
};

export const changeVehicleTypeStatus = (id) => {
  return api.post(`/vehicle-type/handle-status/${id}`);
};

export const addVehicleType = (data) => {
  return api.post("/vehicle-type/create", data);
};

export const editVehicleType = (id, data) => {
  return api.post(`/vehicle-type/edit/${id}`, data);
};
