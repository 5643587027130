import { useNavigate } from "react-router-dom";
import { Success } from "../../Components/Modal";

export default function EditVehicleDetails() {
  const navigate = useNavigate();
  const submit = (e) => {
    e.preventDefault();
    Success("Vehicle Update", "Vehicle has been updated successfully");
  };
  return (
    <div className="app-content content dashboard">
      <div className="content-wrapper">
        <div className="content-body">
          {/* Basic form layout section start */}
          <section id="configuration">
            <div className="row">
              <div className="col-12">
                <div className="card-content collapse show dashCard pt-5 px-5">
                  <div className="row mb-4">
                    <div className="col-12">
                      <div className="back-title">
                        <div className="pageTitleInner d-flex align-items-baseline">
                          <h1 className="pageTitle text-capitalize m-0">
                            Muvrrs Management
                          </h1>
                          <span className="mx-2">
                            <button
                              type="button"
                              className="backLink mr-1"
                              onClick={() => navigate(-1)}
                            >
                              <i className="fas fa-chevron-left" />
                            </button>
                            Edit Vehicle Details
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row dashCard2 py-4 px-4 mb-3">
                    <div className="col-12">
                      <div className="row mb-4">
                        <div className="col-xxl-6">
                          <h1 className="pageTitle text-capitalize mb-4">
                            Edit Vehicle Details
                          </h1>
                          {/* <form action=""> */}
                          <form onSubmit={submit}>
                            <div className="mainDetail my-3">
                              <label htmlFor="" className="mainLabel">
                                Vehicle Name
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                className="mainInput"
                                placeholder="Enter Vehicle Name"
                              />
                            </div>
                            <div className="mainDetail my-3">
                              <label htmlFor="" className="mainLabel">
                                Vehicle Type
                                <span className="text-danger">*</span>
                              </label>
                              <select name="" id="" className="mainInput">
                                <option value={1}>A Type</option>
                                <option value={2}>B Type</option>
                                <option value={3}>C Type</option>
                              </select>
                            </div>
                            <div className="mainDetail my-3">
                              <label htmlFor="" className="mainLabel">
                                Brand Name<span className="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                className="mainInput"
                                placeholder="Enter Brand Name"
                              />
                            </div>
                            <div className="mainDetail my-3">
                              <label htmlFor="" className="mainLabel">
                                Vehicle Color
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                className="mainInput"
                                placeholder="Enter Vehicle Color"
                              />
                            </div>
                            <div className="mainDetail my-3">
                              <label htmlFor="" className="mainLabel">
                                Vehicle Name
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                className="mainInput"
                                placeholder="Enter Vehicle Name"
                              />
                            </div>
                            <div className="mainDetail my-3">
                              <label htmlFor="" className="mainLabel">
                                Plate No<span className="text-danger">*</span>
                              </label>
                              <input
                                type="number"
                                className="mainInput"
                                placeholder="Enter License Plate Number"
                              />
                            </div>
                            <div className="mainDetail my-3">
                              <label htmlFor="" className="mainLabel">
                                License Number
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                type="number"
                                className="mainInput"
                                placeholder="Enter License Number"
                              />
                            </div>
                            <div className="mainDetail my-3">
                              <label htmlFor="" className="mainLabel">
                                License Image
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                type="file"
                                accept="image/*"
                                className="mainInput"
                                multiple=""
                              />
                            </div>
                            <div className="mainDetail my-3">
                              <label htmlFor="" className="mainLabel">
                                Vehicle Image
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                type="file"
                                accept="image/*"
                                className="mainInput"
                                multiple=""
                              />
                            </div>
                            <div className="mainDetail text-end my-3">
                              <button
                                // type="button"
                                // type="submit"
                                className="siteBtn"
                              // data-bs-toggle="modal"
                              // data-bs-target="#editedVehicle"
                              >
                                Update
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div
              className="modal fade"
              id="editedVehicle"
              data-bs-backdrop="static"
              role="dialog"
              aria-labelledby="exampleModalCenterTitle"
            >
              <div
                className="modal-dialog modal-dialog-centered site-modal"
                role="document"
              >
                <div className="modal-content">
                  <div className="modalIndex">
                    <i
                      className="fas fa-times close modal-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    />
                    <div className="text-center">
                      <div className="modal-text-content mb-1">
                        <div className="modalImg">
                          <img
                            src="images/check.png"
                            alt=""
                            className="img-fluid"
                          />
                        </div>
                        <div className="modal-text-content my-4">
                          <p className="modalText">
                            Vehicle has been updated successfully
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </section>
        </div>
      </div>
    </div>
  );
}
