import { format_date } from "../../Utils/helpers";

export default function VehicleDetail({ data }) {
  return (
    <div
      className="modal fade"
      id="vehicleDetails"
      data-bs-backdrop="static"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
    >
      <div
        className="modal-dialog modal-dialog-centered site-modal"
        role="document"
      >
        <div className="modal-content">
          <div className="modalIndex">
            <i
              className="fas fa-times close modal-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            />
            <div className="w-100">
              <div className="text-center mb-5">
                <h3 className="sectionTitle">Vehicle Details</h3>
              </div>
              <div className="text-center my-3">
                <div className="userIimageFrame mx-auto">
                  <img
                    src={
                      data?.vehicle?.vehicleImage?.imageUrl ??
                      "images/truck2.png"
                    }
                    alt=""
                    className="userImage img-fluid"
                  />
                </div>
              </div>
              <div className="mainDetail d-md-flex align-items-end my-3">
                <label htmlFor="" className="mainLabel mainLabel-lg">
                  Vehicle Name:
                </label>
                <p className="mainText">{data?.vehicle?.name}</p>
              </div>
              <div className="mainDetail d-md-flex align-items-end my-3">
                <label htmlFor="" className="mainLabel mainLabel-lg">
                  Vehicle Type:
                </label>
                <p className="mainText">{data?.vehicle?.type?.name}</p>
              </div>
              {/* <div className="mainDetail d-md-flex align-items-end my-3">
                <label htmlFor="" className="mainLabel mainLabel-lg">
                  Vehicle Number:
                </label>
                <p className="mainText">{data?.vehicle?.numberPlate}</p>
              </div> */}
              <div className="mainDetail d-md-flex align-items-end my-3">
                <label htmlFor="" className="mainLabel mainLabel-lg">
                  Date Registered:
                </label>
                <p className="mainText">
                  {data?.createdAt &&
                    format_date(data?.createdAt, "DD/MM/YYYY")}
                </p>
              </div>
              <div className="mainDetail d-md-flex align-items-end my-3">
                <label htmlFor="" className="mainLabel mainLabel-lg">
                  Plate Number:
                </label>
                <p className="mainText">{data?.vehicle?.numberPlate}</p>
              </div>
              {/* <div className="mainDetail d-md-flex flex-wrap gap-2 my-3">
                <img
                  src="images/package1.png"
                  alt=""
                  className="w-30 img-fluid"
                />
                <img
                  src="images/package1.png"
                  alt=""
                  className="w-30 img-fluid"
                />
                <img
                  src="images/package1.png"
                  alt=""
                  className="w-30 img-fluid"
                />
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
