import { useNavigate, useParams } from "react-router-dom";
import { vehicleTypeSchema } from "../../Schema/generalSchema";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Button from "../../Components/Button";
import { useMutation, useQuery } from "react-query";
import { addVehicleType, getVehicleTypeDetails } from "../../Services/VehicleType";
import { Error, Success } from "../../Components/Modal";
import BackButton from "../../Components/BackButton";
import { useEffect, useState } from "react";

export default function ViewVehicleType() {
 const id = useParams();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    resolver: yupResolver(vehicleTypeSchema),
  });
  const statusTypeOptions = [
    { value: "", label: "Select" },
    { value: true, label: "Active" },
    { value: false, label: "Inactive" },
  ];

  const [vehicleData, setVehicleData] = useState();

  const { mutate, isLoading: loadingStatus } = useMutation(
    (data) => addVehicleType(data),
    {
      onSuccess: (res) => {
        Success("Vehicle Type", res.data?.message);
        navigate("/vehicle-type-management");
      },
      onError: (err) => {
        Error(err?.response?.data?.message);
      },
    }
  );

  

  const { data, isLoading, refetch } = useQuery(["vehical_details", id], () =>
    getVehicleTypeDetails(id)
  );
  useEffect(() => {
    if (data?.docs?.length > 0)
      setVehicleData(data?.docs?.[0]);
  }, [data?.docs]);

  console.log(vehicleData,'test')
  const submit = (data) => {
    // console.log(data, "data");
    // console.log({
    //   name: data?.name,
    //   baseFare: Number(data?.cost),
    //   active: JSON.parse(data?.status),
    // });

    mutate({
      name: data?.name,
      baseFare: Number(data?.cost),
      active: JSON.parse(data?.status),
    });
  };

  // console.log(errors);

  return (
    <div className="app-content content dashboard">
      <div className="content-wrapper">
        <div className="content-body">
          {/* Basic form layout section start */}
          <section id="configuration">
            <div className="row">
              <div className="col-12">
                <div className="card-content collapse show dashCard pt-5 px-5">
                  <div className="row mb-4">
                    <div className="col-xl-6">
                      <div className="back-title">
                        <div className="pageTitleInner d-flex align-items-baseline">
                          <h1 className="pageTitle text-capitalize m-0 mt-1">
                          <BackButton />
                          View Vehicle Type
                          </h1>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row py-4 px-4 mb-3">
                    <div className="col-12">
                      <div className="row mb-4">
                        <div className="col-xxl-6">
                          <div className="user-details">
                            <div class="mainDetail mb-md-4">
                              <h5 for="" class="mainLabel mb-2">Type Name</h5>
                              <p class="mainText mb-0">ABC Type</p>
                            </div>
                            <div class="mainDetail mt-md-3">
                              <h5 for="" class="mainLabel mb-2">Status</h5>
                              <p class="mainText mb-0">Active</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}
