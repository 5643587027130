import { useNavigate, Link } from "react-router-dom";
import { AddModal, Confirmation, Reason } from "../../Components/Modal";
import Table from "../../Components/Table";
import StatusHandler from "../../Components/Table/StatusHandler";
import useTableControls from "../../Hooks/useTableControls";
import useFetchData from "../../Hooks/useFetchData";
import { getVehicleRequests } from "../../Services/VehicleRequest";
import { format_date, getSerialNumber } from "../../Utils/helpers";

export default function VehicleManagement() {
    const navigate = useNavigate();

    // const sampleData = [
    //   {
    //     sn: "1",
    //     moverFullName: "Mark Carson",
    //     vehicleName: "ABC Vehicle",
    //     vehicleType: "ABC Type",
    //     date: "01/02/2022",
    //     requestApprovalStatus: "pending",
    //   },
    //   {
    //     sn: "2",
    //     moverFullName: "Mark Carson",
    //     vehicleName: "ABC Vehicle",
    //     vehicleType: "ABC Type",
    //     date: "01/02/2022",
    //     requestApprovalStatus: "pending",
    //   },
    //   {
    //     sn: "3",
    //     moverFullName: "Mark Carson",
    //     vehicleName: "ABC Vehicle",
    //     vehicleType: "ABC Type",
    //     date: "01/02/2022",
    //     requestApprovalStatus: "accepted",
    //   },
    //   {
    //     sn: "4",
    //     moverFullName: "Mark Carson",
    //     vehicleName: "ABC Vehicle",
    //     vehicleType: "ABC Type",
    //     date: "01/02/2022",
    //     requestApprovalStatus: "accepted",
    //   },
    // ];

    const {
        perPage,
        setPerPage,
        status,
        setStatus,
        search_string,
        setSearchString,
        from,
        setFrom,
        to,
        setTo,
    } = useTableControls();

    const {
        // INTERNAL EXPORTS
        setPage,
        // REACT QUERY EXPORTS
        isFetching,
        isLoading,
        data,
        refetch,
    } = useFetchData("vehicleRequest_logs", getVehicleRequests, [
        perPage,
        search_string,
        "approved",
        from,
        to,
    ]);
    // console.log("vehicleRequest_logs Data: ", data?.data);

    return (
        <div className="app-content content dashboard">
            <div className="content-wrapper">
                <div className="content-body">
                    {/* Basic form layout section start */}
                    <section id="configuration">
                        <div className="row">
                            <div className="col-12">
                                <div className="card-content collapse show dashCard pt-5 px-5">
                                    <div className="row mb-4">
                                        <div className="col-xl-6">
                                            <div className="back-title">
                                                <div className="pageTitleInner">
                                                    <h1 className="pageTitle text-capitalize mb-0 mt-1">Vehicle Management</h1>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-6">
                                            <div className="text-end">
                                                <Link
                                                    to="/vehicle-management/vehicle-requests"
                                                    className="siteBtn mt-1"
                                                >
                                                    Requests
                                                </Link>
                                            </div>
                                        </div>
                                    </div>

                                    <Table
                                        key={"mover-management-signup-requests-table"}
                                        tableTitles={[
                                            "S.No.",
                                            "Muvrrs Full Name",
                                            "Vehicle Name",
                                            "Vehicle Type",
                                            "Date Registered",
                                            "Action",
                                        ]}
                                        dateFilter
                                        showEntries
                                        isLoading={isLoading}
                                        isFetching={isFetching}
                                        data={data?.data?.data?.docs}
                                        totalPages={data?.data?.data?.totalPages}
                                        totalDocs={data?.data?.data?.totalDocs}
                                        setPage={setPage}
                                        setSearchString={setSearchString}
                                        status={status}
                                        setStatus={setStatus}
                                        perPage={perPage}
                                        setPerPage={setPerPage}
                                        from={from}
                                        setFrom={setFrom}
                                        to={to}
                                        setTo={setTo}
                                    // status_options={[
                                    //   {
                                    //     label: "Pending",
                                    //     value: "pending",
                                    //   },
                                    //   {
                                    //     label: "Approved",
                                    //     value: "approved",
                                    //   },
                                    //   {
                                    //     label: "Rejected",
                                    //     value: "rejected",
                                    //   },
                                    // ]}
                                    >
                                        {!isFetching &&
                                            data?.data?.data?.docs?.map((item, index) => (
                                                <tr>
                                                    <td>{getSerialNumber(data?.data?.data, index)}</td>
                                                    <td>{item?.user?.firstName}</td>
                                                    <td>{item?.name}</td>
                                                    <td>{item?.type?.name}</td>
                                                    <td>
                                                        {item?.createdAt &&
                                                            format_date(item?.createdAt, "MM/DD/YYYY")}
                                                    </td>
                                                    <td>
                                                        <StatusHandler
                                                            key={`${item?._id}-status`}
                                                            view
                                                            // approval
                                                            item={item}
                                                            // isLoading={loadingStatus}
                                                            redirectUrl={`/vehicle-management/vehicle-details/${item?._id}`}
                                                        // onClick={() => {
                                                        //   if (item.requestApprovalStatus === "pending") {
                                                        //     Confirmation(
                                                        //       `Are You Sure You Want To Approve The Signup Request ?`,
                                                        //       "Yes",
                                                        //       () => console.log("Status Changed")
                                                        //     );
                                                        //   } else {
                                                        //     Reason(
                                                        //       "Are you sure you want to reject the signup request ?",
                                                        //       "Yes",
                                                        //       (val) => console.log("Val", val)
                                                        //     );
                                                        //   }
                                                        // }}
                                                        />
                                                    </td>
                                                </tr>
                                            ))}
                                    </Table>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    );
}
