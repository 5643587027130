import api from "../Utils/axios";

export const getNotifications = (page, perPage) => {
  return api.get(`/notification/get`, {
    params: {
      page,
      limit: perPage,
    },
  });
};
