import Table from "../../Components/Table";

export default function PaymentLogs() {
  const sampleData = [
    // {
    //   sn: "1",
    //   bookingId: "123",
    //   userName: "Mark Carson",
    //   moverName: "John Carson",
    //   pickupLocation: "Abc road 123 street",
    //   dropoffLocation: "Bbc road 123 street",
    //   totalAmount: "123",
    //   moverShare: "123",
    //   adminShare: "123",
    // },
    // {
    //   sn: "2",
    //   bookingId: "123",
    //   userName: "Mark Carson",
    //   moverName: "John Carson",
    //   pickupLocation: "Abc road 123 street",
    //   dropoffLocation: "Bbc road 123 street",
    //   totalAmount: "123",
    //   moverShare: "123",
    //   adminShare: "123",
    // },
    // {
    //   sn: "3",
    //   bookingId: "123",
    //   userName: "Mark Carson",
    //   moverName: "John Carson",
    //   pickupLocation: "Abc road 123 street",
    //   dropoffLocation: "Bbc road 123 street",
    //   totalAmount: "123",
    //   moverShare: "123",
    //   adminShare: "123",
    // },
    // {
    //   sn: "4",
    //   bookingId: "123",
    //   userName: "Mark Carson",
    //   moverName: "John Carson",
    //   pickupLocation: "Abc road 123 street",
    //   dropoffLocation: "Bbc road 123 street",
    //   totalAmount: "123",
    //   moverShare: "123",
    //   adminShare: "123",
    // },
  ];

  return (
    <div className="app-content content dashboard">
      <div className="content-wrapper">
        <div className="content-body">
          {/* Basic form layout section start */}
          <section id="configuration">
            <div className="row">
              <div className="col-12">
                <div className="card-content collapse show dashCard pt-5 px-5">
                  <Table
                    key={"payment-logs-table"}
                    showTableName
                    tableName={"Payment Logs"}
                    tableTitles={[
                      "S.No.",
                      "Booking Id",
                      "User Name",
                      "Muvrrs Name",
                      "Pickup Location",
                      "Drop-off Location",
                      "Total Amount",
                      "Muvrrs Share",
                      "Admin Share",
                    ]}
                    dateFilter
                    showEntries
                    data={sampleData}
                  // sortBy
                  // isLoading={isLoading}
                  // isFetching={isFetching}
                  // data={data?.data?.users}
                  // totalPages={data?.data?.totalPages}
                  // totalDocs={data?.data?.totalDocs}
                  // setPage={setPage}
                  // setSearchString={setSearchString}
                  // status={status}
                  // setStatus={setStatus}
                  // perPage={perPage}
                  // setPerPage={setPerPage}
                  // from={from}
                  // setFrom={setFrom}
                  // to={to}
                  // setTo={setTo}
                  >
                    {sampleData?.map((item, index) => (
                      <tr>
                        <td>{item.sn}</td>
                        <td>{item.bookingId}</td>
                        <td>{item.userName}</td>
                        <td>{item.moverName}</td>
                        <td>{item.pickupLocation}</td>
                        <td>{item.dropoffLocation}</td>
                        <td>${item.totalAmount}</td>
                        <td>${item.moverShare}</td>
                        <td>${item.adminShare}</td>
                      </tr>
                    ))}
                  </Table>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}
