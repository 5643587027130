import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { setPasswordSchema } from "../../Schema/authSchema";
import { useMutation } from "react-query";
import { resetPassword } from "../../Services/Auth";
import { Error, Success } from "../../Components/Modal";
import Button from "../../Components/Button";

export default function SetPassword() {
  const [showPassword, setShowPassword] = useState({
    newPassword: false,
    confirmPassword: false,
  });
  const navigate = useNavigate();
  const [apiData, setApiData] = useState({
    email: localStorage.getItem("email"),
    code: localStorage.getItem("code"),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(setPasswordSchema),
  });

  const { mutate, isLoading } = useMutation((data) => resetPassword(data), {
    retry: false,
    onSuccess: (res) => {
      localStorage.removeItem("email");
      localStorage.removeItem("code");
      Success("Reset Password", res.data?.message);
      navigate("/");
    },
    onError: (err) => {
      // console.log("resetPassword Err: ", err);
      Error(err.response.data?.message);
    },
  });

  const submit = (data) => {
    console.log(data, "data");

    mutate({
      email: apiData?.email,
      password: data?.confirmPassword,
      otp: apiData?.code,
    });
  };

  console.log(errors);

  return (
    <section className="loginPage">
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-lg-6 px-4 loginBgLeft d-none d-lg-block">
            <div className="loginLeftContent mb-3">
              <img
                src="images/loginLogo.png"
                alt=""
                className="img-fluid loginLogo"
              />
              <p>
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                diam nonumy eirmod tempor invidunt ut labore et dolore magna
                aliquyam erat, sed diam voluptua. At vero eos et accusam et
                justo duo dolores et ea rebum.{" "}
              </p>
            </div>
            <img src="images/loginLeftImage.png" alt="" className="img-fluid" />
          </div>
          <div className="col-lg-6 loginBgRight">
            <div className="loginCard">
              <div className="text-center d-block d-lg-none">
                <img
                  src="images/loginLogo.png"
                  alt=""
                  className="loginLogo img-fluid mb-2"
                />
              </div>
              <div className="formBox">
                <div className="formHeading my-5">
                  <h2>Password Recovery</h2>
                </div>
                <form
                  // action="login.php"
                  // method="POST"
                  className="py-2"
                  onSubmit={handleSubmit(submit)}
                >
                  <div className="form-field">
                    <label htmlFor="" className="siteLabel">
                      New Password<span className="text-danger">*</span>
                    </label>
                    <div className="passwordWrapper position-relative">
                      <input
                        type={showPassword.newPassword ? "text" : "password"}
                        className="siteInput passInput"
                        placeholder="Enter New Password"
                        name=""
                        id=""
                        {...register("newPassword")}
                      />
                      <button
                        type="button"
                        className="passDisplay"
                        onClick={() =>
                          setShowPassword({
                            ...showPassword,
                            newPassword: !showPassword.newPassword,
                          })
                        }
                      >
                        <i
                          className={
                            showPassword.newPassword
                              ? "fas fa-eye"
                              : "fas fa-eye-slash"
                          }
                          aria-hidden="true"
                        />
                      </button>
                      <span className="text-danger">
                        {errors?.newPassword?.message}
                      </span>
                    </div>
                  </div>
                  <div className="form-field">
                    <label htmlFor="" className="siteLabel">
                      Confirm Password<span className="text-danger">*</span>
                    </label>
                    <div className="passwordWrapper position-relative">
                      <input
                        type={
                          showPassword.confirmPassword ? "text" : "password"
                        }
                        className="siteInput passInput"
                        placeholder="Enter Confirm Password"
                        name=""
                        id=""
                        {...register("confirmPassword")}
                      />
                      <button
                        type="button"
                        className="passDisplay"
                        onClick={() =>
                          setShowPassword({
                            ...showPassword,
                            confirmPassword: !showPassword.confirmPassword,
                          })
                        }
                      >
                        <i
                          className={
                            showPassword.confirmPassword
                              ? "fas fa-eye"
                              : "fas fa-eye-slash"
                          }
                          aria-hidden="true"
                        />
                      </button>
                      <span className="text-danger">
                        {errors?.confirmPassword?.message}
                      </span>
                    </div>
                  </div>
                  <div className="form-field mt-3 mt-lg-5 text-center">
                    <Button
                      type="submit"
                      className="siteBtn w-100 mb-3"
                      loading={isLoading}
                    >
                      Update
                    </Button>
                    <Link
                      to="/"
                      className="siteBtn2 w-100 mb-3"
                      onClick={() => {
                        localStorage.removeItem("email");
                        localStorage.removeItem("code");
                      }}
                    >
                      Back To Sign In
                    </Link>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
