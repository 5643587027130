import api from "../Utils/axios";
import { filterParams } from "../Utils/helpers";

export const getSignupRequests = (
    page,
    perPage,
    search_string,
    status,
    from,
    to
) => {
    return api.get(`/signup-requests/get`, {
        params: filterParams({
            page,
            limit: perPage,
            keyword: search_string,
            status,
            "createdAt[from]": from,
            "createdAt[to]": to,
        }),
    });
};


export const getSignupBusinessRequests = (
    page,
    perPage,
    search_string,
    status,
    from,
    to
) => {
    return api.get(`/signup-requests/getBusiness`, {
        params: filterParams({
            page,
            limit: perPage,
            keyword: search_string,
            status,
            "createdAt[from]": from,
            "createdAt[to]": to,
        }),
    });
};

export const getSignupBusinessMuvvrs = (
    page,
    perPage,
    search_string,
    status,
    from,
    to,
    id,
) => {
    return api.get(`/signup-requests/getBusinessMuvvrs/${id}`, {
        params: filterParams({
            page,
            limit: perPage,
            keyword: search_string,
            status,
            "createdAt[from]": from,
            "createdAt[to]": to,
        }),
    });
};

export const getSignupRequestDetails = (id) => {
    return api.get(`/signup-requests/get/${id}`);
};

export const getBusinessRequestDetails = (id) => {
    return api.get(`/signup-requests/getBusiness/${id}`);
};

export const getBusinessApprovedDetails = (id) => {
    return api.get(`/signup-requests/getBusinessApproved/${id}`);
};

export const changeSignupRequestStatus = (id, data) => {
    return api.post(`/signup-requests/handle-request/${id}`, data);
};
