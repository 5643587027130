
import { FaChevronLeft } from "react-icons/fa"
import { useNavigate } from 'react-router-dom'

const BackButton = () => {

    const navigate = useNavigate()

    return (
        <button
            type="button"
            className="backLink"
            onClick={() => navigate(-1)}
        >
            <FaChevronLeft className="cursor-pointer" />
            {/* <FontAwesomeIcon icon={FaArrowLeft} /> */}
        </button>

        // <FaArrowLeft onClick={() => navigate(-1)} className="cursor-pointer me-2" />
    )

}

export default BackButton

