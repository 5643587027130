import Table from "../../Components/Table";
import StatusHandler from "../../Components/Table/StatusHandler";
import useFetchData from "../../Hooks/useFetchData";
import useTableControls from "../../Hooks/useTableControls";
import { getAllRides } from "../../Services/Ride";
import { format_date, getSerialNumber } from "../../Utils/helpers";

export default function RidesManagement() {
  // const sampleData = [
  //   {
  //     sn: "1",
  //     postedBy: "Abc User",
  //     email: "abc@xyz.com",
  //     datePosted: "01/02/2022",
  //     acceptedBy: "Abc User",
  //     status: "pending",
  //   },
  //   {
  //     sn: "2",
  //     postedBy: "Abc User",
  //     email: "abc@xyz.com",
  //     datePosted: "01/02/2022",
  //     acceptedBy: "Abc User",
  //     status: "rejected",
  //   },
  //   {
  //     sn: "3",
  //     postedBy: "Abc User",
  //     email: "abc@xyz.com",
  //     datePosted: "01/02/2022",
  //     acceptedBy: "Abc User",
  //     status: "completed",
  //   },
  //   {
  //     sn: "4",
  //     postedBy: "Abc User",
  //     email: "abc@xyz.com",
  //     datePosted: "01/02/2022",
  //     acceptedBy: "Abc User",
  //     status: "ongoing",
  //   },
  //   {
  //     sn: "5",
  //     postedBy: "Abc User",
  //     email: "abc@xyz.com",
  //     datePosted: "01/02/2022",
  //     acceptedBy: "Abc User",
  //     status: "reported",
  //   },
  // ];

  const {
    perPage,
    setPerPage,
    status,
    setStatus,
    search_string,
    setSearchString,
    from,
    setFrom,
    to,
    setTo,
  } = useTableControls();

  const {
    // INTERNAL EXPORTS
    setPage,
    // REACT QUERY EXPORTS
    isFetching,
    isLoading,
    data,
    refetch,
  } = useFetchData("ride-logs", getAllRides, [
    perPage,
    search_string,
    status,
    from,
    to,
  ]);
  // console.log("ride-logs Data: ", data?.data);

  return (
    <div className="app-content content dashboard">
      <div className="content-wrapper">
        <div className="content-body">
          {/* Basic form layout section start */}
          <section id="configuration">
            <div className="row">
              <div className="col-12">
                <div className="card-content collapse show dashCard pt-5 px-5">
                  <Table
                    key={"rides-management-table"}
                    showTableName
                    tableName={"Rides Management"}
                    tableTitles={[
                      "S.No.",
                      "Posted By",
                      "Email Address",
                      "Date Posted",
                      "Accepted By",
                      "Status",
                      "Action",
                    ]}
                    dateFilter
                    showEntries
                    sortBy
                    isLoading={isLoading}
                    isFetching={isFetching}
                    data={data?.data?.data?.docs}
                    totalPages={data?.data?.data?.totalPages}
                    totalDocs={data?.data?.data?.totalDocs}
                    setPage={setPage}
                    setSearchString={setSearchString}
                    status={status}
                    setStatus={setStatus}
                    perPage={perPage}
                    setPerPage={setPerPage}
                    from={from}
                    setFrom={setFrom}
                    to={to}
                    setTo={setTo}
                    status_options={[
                      {
                        label: "Pending",
                        value: "pending",
                      },
                      {
                        label: "Accepted",
                        value: "accepted",
                      },
                      {
                        label: "Rejected",
                        value: "rejected",
                      },
                      {
                        label: "Started",
                        value: "started",
                      },
                      {
                        label: "Completed",
                        value: "completed",
                      },
                      {
                        label: "Cancelled",
                        value: "cancelled",
                      },
                    ]}
                  >
                    {!isFetching &&
                      data?.data?.data?.docs?.map((item, index) => (
                        <tr>
                          <td>{getSerialNumber(data?.data?.data, index)}</td>
                          <td>{item?.user?.firstName}</td>
                          <td>{item?.user?.email}</td>
                          <td>
                            {item?.createdAt &&
                              format_date(item?.createdAt, "MM/DD/YYYY")}
                          </td>
                          <td>{item?.driver?.firstName}</td>
                          <td>{item.status}</td>
                          <td>
                            <StatusHandler
                              key={`${item?._id}-status`}
                              view
                              redirectUrl={`/ride-management/ride-details/${item?._id}`}
                            />
                          </td>
                        </tr>
                      ))}
                  </Table>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}
