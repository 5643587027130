import api from "../Utils/axios";
import { filterParams } from "../Utils/helpers";

export const getAllUsers = (
    page,
    perPage,
    role = "user",
    search_string,
    status,
    from,
    to,
    adminApproved
) => {
    return api.get(`/user/get`, {
        params: filterParams({
            page,
            limit: perPage,
            role,
            keyword: search_string,
            active: status,
            adminApproved,
            "createdAt[from]": from,
            "createdAt[to]": to,
        }),
    });
};

export const getUserDetails = (id) => {
    return api.get(`/user/get`, {
        params: {
            _id: id,
        },
    });
};

export const getBusinessMuvvrDetails = (id) => {
    return api.get(`/signup-requests/myMuvvrs`, {
        params: {
            _id: id,
        },
    });
};

export const changeUserStatus = (id) => {
    return api.post(`/user/handle-status/${id}`);
};
