import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getReportDetails } from "../../Services/Reports";
import { format_date } from "../../Utils/helpers";

export default function ReportDetails() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [report, setReport] = useState({});

  const { data, isLoading, refetch } = useQuery(["report_details", id], () =>
    getReportDetails(id)
  );

  useEffect(() => {
    if (data?.data?.data?.docs?.length > 0)
      setReport(data?.data?.data?.docs?.[0]);
  }, [data?.data?.data?.docs]);

  return (
    <div className="app-content content dashboard">
      <div className="content-wrapper">
        <div className="content-body">
          {/* Basic form layout section start */}
          <section id="configuration">
            <div className="row">
              <div className="col-12">
                <div className="card-content collapse show dashCard pt-5 px-5">
                  <div className="row mb-4">
                    <div className="col-xl-6">
                      <div className="back-title">
                        <div className="pageTitleInner d-flex align-items-baseline">
                          <h1 className="pageTitle text-capitalize m-0 mt-1">
                            Reports
                          </h1>
                          <span className="mx-2">
                            <button
                              type="button"
                              className="backLink mr-1"
                              onClick={() => navigate("/feedbacks")}
                            >
                              <i className="fas fa-chevron-left" />
                            </button>
                            Report Details
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <Link
                    to={`/ride-management/ride-details/${report?.bookingId?._id}`}
                    className="siteBtn2 mt-1 ms-4"
                  >
                    View Booking
                  </Link>
                  <div className="row py-4 px-4 mb-3">
                    <div className="col-12">
                      <div className="row mb-4">
                        <div className="col-xxl-6">
                          <div className="mainDetail dashCard2 d-xl-flex align-items-baseline py-2 px-3 my-3">
                            <label
                              htmlFor=""
                              className="mainLabel mainLabel-lg"
                            >
                              Reported By:
                            </label>
                            <p className="mainText">{report?.reportedBy?.firstName}</p>
                          </div>
                          <div className="mainDetail dashCard2 d-xl-flex align-items-baseline py-2 px-3 my-3">
                            <label
                              htmlFor=""
                              className="mainLabel mainLabel-lg"
                            >
                              Email Address:
                            </label>
                            <p className="mainText">{report?.reportedBy?.email}</p>
                          </div>
                          <div className="mainDetail dashCard2 d-xl-flex align-items-baseline py-2 px-3 my-3">
                            <label
                              htmlFor=""
                              className="mainLabel mainLabel-lg"
                            >
                              Muvrr's Name:
                            </label>
                            <p className="mainText">{report?.bookingId?.driver?.firstName}</p>
                          </div>
                          <div className="mainDetail dashCard2 d-xl-flex align-items-baseline py-2 px-3 my-3">
                            <label
                              htmlFor=""
                              className="mainLabel mainLabel-lg"
                            >
                              Muvrr's Email Address:
                            </label>
                            <p className="mainText">{report?.bookingId?.driver?.email}</p>
                          </div>
                          <div className="mainDetail dashCard2 d-xl-flex align-items-baseline py-2 px-3 my-3">
                            <label
                              htmlFor=""
                              className="mainLabel mainLabel-lg"
                            >
                              Type:
                            </label>
                            <p className="mainText">
                              {report?.type ?? "-"}
                            </p>
                          </div>
                          <div className="mainDetail dashCard2 d-xl-flex align-items-baseline py-2 px-3 my-3">
                            <label
                              htmlFor=""
                              className="mainLabel mainLabel-lg"
                            >
                              Date Submitted:
                            </label>
                            <p className="mainText">
                              {report?.createdAt &&
                                format_date(report?.createdAt, "DD/MM/YYYY")}
                            </p>
                          </div>
                          <div className="mainDetail dashCard2 d-xl-flex align-items-baseline py-2 px-3 my-3">
                            <label
                              htmlFor=""
                              className="mainLabel mainLabel-lg"
                            >
                              Broken Item Cost:
                            </label>
                            <p className="mainText">
                              ${report?.brokenItemCost}
                            </p>
                          </div>
                          <div className="mainDetail dashCard2 d-xl-flex align-items-baseline py-2 px-3 my-3">
                            <label
                              htmlFor=""
                              className="mainLabel mainLabel-lg"
                            >
                              Description:
                            </label>
                            <p className="mainText">{report?.details}</p>
                          </div>
                          <div className="mainDetail dashCard2 d-xl-flex  py-2 px-3 my-3">
                            <label
                              htmlFor=""
                              className="mainLabel mainLabel-lg"
                            >
                              Images:
                            </label>
                            <div className="mainDetail d-flex align-items-baseline flex-wrap my-1">
                              {report?.images?.map((item) => (
                                <img
                                  key={item._id}
                                  src={
                                    item?.imageUrl ??
                                    "images/truck1.png"
                                  }
                                  alt=""
                                  className="mainImage"
                                />
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}
