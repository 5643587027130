import { Link, useParams, useNavigate } from "react-router-dom";
import Table from "../../Components/Table";
import useTableControls from "../../Hooks/useTableControls";
import { useMutation, useQuery } from "react-query";
import { changeUserStatus, getAllUsers, getUserDetails } from "../../Services/User";
import { useEffect, useState } from "react";
import useFetchData from "../../Hooks/useFetchData";
import { getAllRides } from "../../Services/Ride";
import { format_date, getSerialNumber } from "../../Utils/helpers";
import BackButton from "../../Components/BackButton";
import { Success } from "../../Components/Modal";
import StatusHandler from "../../Components/Table/StatusHandler";
import {
    changeSignupRequestStatus,
    getBusinessRequestDetails,
    getBusinessApprovedDetails
} from "../../Services/SignupRequest";
import { getSignupBusinessRequests } from "../../Services/SignupRequest";
import { getSignupBusinessMuvvrs } from "../../Services/SignupRequest";

export default function BusinessManagementDetails() {
    const { id } = useParams();


    const {
        perPage,
        setPerPage,
        status,
        setStatus,
        search_string,
        setSearchString,
        from,
        setFrom,
        to,
        setTo,
    } = useTableControls();

    const {
        // INTERNAL EXPORTS
        setPage,
        // REACT QUERY EXPORTS
        isFetching,
        isLoading1,
        data: data1,

    } = useFetchData("signupRequest_logs", getSignupBusinessMuvvrs, [
        perPage,
        search_string,
        "",
        from,
        to,
        id
    ]);


    const navigate = useNavigate();

    const [requestDetail, setRequestDetail] = useState({});

    const { data, isLoading, refetch } = useQuery(
        ["signupRequest_details", id],
        () => getBusinessApprovedDetails(id)
    );

    useEffect(() => {
        if (data?.data?.data)
            setRequestDetail(data?.data?.data);
    }, [data]);

    const { mutate, isLoading: loadingStatus } = useMutation(
        (data) => changeSignupRequestStatus(id, data),
        {
            onSuccess: (res) => {
                refetch();
                Success("Signup Request Status", res.data?.message);
            },
            onError: (err) => {
                Error(err?.response?.data?.message);
            },
        }
    );
    return (
        <div className="app-content content dashboard business-details">
            <div className="content-wrapper">
                <div className="content-body">
                    {/* Basic form layout section start */}
                    <section id="configuration">
                        <div className="row">
                            <div className="col-12">
                                <div className="card-content collapse show dashCard pt-5 px-5">
                                    <div className="row mb-4">
                                        <div className="col-12">
                                            <div className="back-title">
                                                <BackButton />
                                                <div className="pageTitleInner d-flex align-items-baseline">
                                                    <h1 className="pageTitle text-capitalize m-0">
                                                        Business Details
                                                    </h1>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="dashCard2 mb-5">
                                        <div className="d-lg-flex py-4 px-4 border-bottom">
                                            <div className="me-4 me-xxl-5 pe-xxl-3">
                                                <div className="userIimageFrame my-2 ">
                                                    <img
                                                        src={
                                                            requestDetail?.profileImage?.imageUrl ??
                                                            "images/avatar.png"
                                                        }
                                                        alt=""
                                                        className="userImage img-fluid"
                                                    />
                                                </div>
                                            </div>
                                            <div className="flex-grow-1">
                                                <div className="text-lg-end">
                                                    <div className="statusBox">
                                                        <p className="m-0">
                                                            Status:{" "}
                                                            <span>
                                                                {requestDetail?.active === true
                                                                    ? "Active"
                                                                    : requestDetail?.active === false && "Inactive"}
                                                            </span>
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="mt-2 pb-3 pb-lg-4 pb-xxl-4">
                                                    <div className="mainDetail d-lg-flex align-items-baseline my-1">
                                                        <label htmlFor="" className="mainLabel">
                                                            Company Name
                                                        </label>
                                                        <p className="mainText">{requestDetail?.companyName}</p>
                                                    </div>
                                                    <div className="mainDetail d-lg-flex align-items-baseline my-1">
                                                        <label htmlFor="" className="mainLabel">
                                                            Email Address:
                                                        </label>
                                                        <p className="mainText">{requestDetail?.email}</p>
                                                    </div>
                                                    <div className="mainDetail d-lg-flex align-items-baseline my-1">
                                                        <label htmlFor="" className="mainLabel">
                                                            Phone Number:
                                                        </label>
                                                        <p className="mainText">+ {requestDetail?.countryCode} {requestDetail?.phone}</p>
                                                    </div>
                                                    <div className="mainDetail d-lg-flex align-items-baseline my-1">
                                                        <label htmlFor="" className="mainLabel">
                                                            Company Address I
                                                        </label>
                                                        <p className="mainText">{requestDetail?.companyAddress}</p>
                                                    </div>
                                                    <div className="mainDetail d-lg-flex align-items-baseline my-1">
                                                        <label htmlFor="" className="mainLabel">Google Pin Location</label>
                                                        <p className="mainText">{requestDetail?.companyLocation}</p>
                                                    </div>
                                                    <div className="mainDetail d-lg-flex align-items-baseline my-1">
                                                        <label htmlFor="" className="mainLabel">Company Description</label>
                                                        <p className="mainText">{requestDetail?.companyDescription}</p>
                                                    </div>
                                                    <div className="mainDetail d-lg-flex align-items-baseline my-1">
                                                        <label htmlFor="" className="mainLabel">EIN Number</label>
                                                        <p className="mainText">{requestDetail?.einNumber}</p>
                                                    </div>
                                                    <div className="mainDetail d-lg-flex align-items-baseline my-1">
                                                        <label htmlFor="" className="mainLabel">Documents</label>
                                                        <p className="mainText">abc.pdf</p>
                                                    </div>
                                                    <div className="mainDetail d-lg-flex align-items-baseline my-1">
                                                        <label htmlFor="" className="mainLabel">No. of Vehicles That Mover Owns</label>
                                                        <p className="mainText">{requestDetail?.vehicles?.length}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {requestDetail?.vehicles?.map((vehicle) =>
                                            <div className="py-4 px-4 border-bottom">
                                                {/* <h5 class="text-capitalize mb-4 mt-3">No. of Vehicles That Mover Owns: <span className="ms-4">02</span></h5> */}
                                                <h1 class="pageTitle text-capitalize mb-3">Vehicle Details</h1>
                                                <div className="user-details mb-3">
                                                    <div className="row">
                                                        <div className="col-xl-10 col-xxl-8">
                                                            <div className="row">
                                                                <div className="col-md-6 mt-3">
                                                                    <div className="mainDetail d-xl-flex align-items-baseline">
                                                                        <label htmlFor="" className="mainLabel mb-0">Vehicle Name:</label>
                                                                        <p className="mainText mb-0">{vehicle?.name}</p>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-6 mt-3">
                                                                    <div className="mainDetail d-xl-flex align-items-baseline">
                                                                        <label htmlFor="" className="mainLabel mb-0">Brand Name :</label>
                                                                        <p className="mainText mb-0">{vehicle?.brandName}</p>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-6 mt-3">
                                                                    <div className="mainDetail d-xl-flex align-items-baseline">
                                                                        <label htmlFor="" className="mainLabel mb-0">Vehicle Type:</label>
                                                                        <p className="mainText mb-0">{vehicle?.type?.name}</p>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-6 mt-3">
                                                                    <div className="mainDetail d-xl-flex align-items-baseline">
                                                                        <label htmlFor="" className="mainLabel mb-0">Vehicle Color:</label>
                                                                        <p className="mainText mb-0">{vehicle?.color}</p>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-6 mt-3">
                                                                    <div className="mainDetail d-xl-flex align-items-baseline">
                                                                        <label htmlFor="" className="mainLabel mb-0">Troller Size:</label>
                                                                        <p className="mainText mb-0">{vehicle?.trollerSize}</p>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-6 mt-3">
                                                                    <div className="mainDetail d-xl-flex align-items-baseline">
                                                                        <label htmlFor="" className="mainLabel mb-0">Plate No:</label>
                                                                        <p className="mainText mb-0">{vehicle?.numberPlate}</p>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-6 mt-3">
                                                                    <div className="mainDetail d-xl-flex align-items-baseline">
                                                                        <label htmlFor="" className="mainLabel mb-0">Specifications:</label>
                                                                        <p className="mainText mb-0">{vehicle?.specification}</p>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6 mt-3">
                                                                    <div className="mainDetail d-xl-flex align-items-baseline">
                                                                        <label htmlFor="" className="mainLabel mb-0">GVWR :</label>
                                                                        <p className="mainText mb-0">{vehicle?.gvwr}</p>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6 mt-3">
                                                                    <div className="mainDetail d-xl-flex align-items-baseline">
                                                                        <label htmlFor="" className="mainLabel mb-0">Accessories:</label>
                                                                        <p className="mainText mb-0">{vehicle?.accessories}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row mt-4">
                                                                <div className="col-xxl-10">
                                                                    <div className="vehicle-wrap d-flex align-items-baseline flex-wrap my-1 gap-4">
                                                                        {
                                                                            vehicle?.vehicleImage?.map(item => (
                                                                                <div className="vehicle-img">
                                                                                    <img
                                                                                        src={
                                                                                            item?.imageUrl ??
                                                                                            "images/truck1.png"
                                                                                        }
                                                                                        alt=""
                                                                                    />
                                                                                </div>
                                                                            ))
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        )}
                                        {/* <div className="py-4 px-4">
                                            <h1 class="pageTitle text-capitalize mb-3">Vehicle Details</h1>
                                            <div className="user-details">
                                                <div className="row">
                                                    <div className="col-xl-10 col-xxl-8">
                                                        <div className="row">
                                                            <div className="col-md-6 mt-3">
                                                                <div className="mainDetail d-xl-flex align-items-baseline">
                                                                    <label htmlFor="" className="mainLabel mb-0">Vehicle Name:</label>
                                                                    <p className="mainText mb-0">{driver?.vehicle?.name}</p>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6 mt-3">
                                                                <div className="mainDetail d-xl-flex align-items-baseline">
                                                                    <label htmlFor="" className="mainLabel mb-0">Brand Name :</label>
                                                                    <p className="mainText mb-0">2000 KG</p>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6 mt-3">
                                                                <div className="mainDetail d-xl-flex align-items-baseline">
                                                                    <label htmlFor="" className="mainLabel mb-0">Vehicle Type:</label>
                                                                    <p className="mainText mb-0">{driver?.vehicle?.type?.name}</p>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6 mt-3">
                                                                <div className="mainDetail d-xl-flex align-items-baseline">
                                                                    <label htmlFor="" className="mainLabel mb-0">Vehicle Color:</label>
                                                                    <p className="mainText mb-0">{driver?.vehicle?.color}</p>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6 mt-3">
                                                                <div className="mainDetail d-xl-flex align-items-baseline">
                                                                    <label htmlFor="" className="mainLabel mb-0">Troller Size:</label>
                                                                    <p className="mainText mb-0">Medium Trailer</p>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6 mt-3">
                                                                <div className="mainDetail d-xl-flex align-items-baseline">
                                                                    <label htmlFor="" className="mainLabel mb-0">Plate No:</label>
                                                                    <p className="mainText mb-0">{driver?.vehicle?.numberPlate}</p>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6 mt-3">
                                                                <div className="mainDetail d-xl-flex align-items-baseline">
                                                                    <label htmlFor="" className="mainLabel mb-0">Specifications:</label>
                                                                    <p className="mainText mb-0">Open</p>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6 mt-3">
                                                                <div className="mainDetail d-xl-flex align-items-baseline">
                                                                    <label htmlFor="" className="mainLabel mb-0">GVWR :</label>
                                                                    <p className="mainText mb-0">2000 KG</p>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6 mt-3">
                                                                <div className="mainDetail d-xl-flex align-items-baseline">
                                                                    <label htmlFor="" className="mainLabel mb-0">Accessories:</label>
                                                                    <p className="mainText mb-0">Tie Down Straps</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row mt-4">
                                                            <div className="col-xxl-10">
                                                                <div className="vehicle-wrap d-flex align-items-baseline flex-wrap my-1 gap-4">
                                                                    {
                                                                        driver?.vehicle?.vehicleImage?.map(item => (
                                                                            <div className="vehicle-img">
                                                                                <img
                                                                                    src={
                                                                                        item?.imageUrl ??
                                                                                        "images/truck1.png"
                                                                                    }
                                                                                    alt=""
                                                                                />
                                                                            </div>
                                                                        ))
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}
                                    </div>
                                    {/* 
                  <Table
                    key={"mover-details-booking-logs-table"}
                    showTableName
                    tableName={"Muvrr Logs"}
                    tableTitles={[
                      "S.No.",
                      "Full Name",
                      "Email Address",
                      "Date",
                      "Status",
                      "Phone No.",
                      "Actions",
                    ]}
                    dateFilter
                    showEntries
                    sortBy
                    isLoading={rideLoading}
                    isFetching={rideFetching}
                    data={rideData?.data?.data?.docs}
                    totalPages={rideData?.data?.data?.totalPages}
                    totalDocs={rideData?.data?.data?.totalDocs}
                    setPage={setPage}
                    setSearchString={setSearchString}
                    status={status}
                    setStatus={setStatus}
                    perPage={perPage}
                    setPerPage={setPerPage}
                    from={from}
                    setFrom={setFrom}
                    to={to}
                    setTo={setTo}
                    status_options={[
                      {
                        label: "Pending",
                        value: "pending",
                      },
                      {
                        label: "Accepted",
                        value: "accepted",
                      },
                      {
                        label: "Rejected",
                        value: "rejected",
                      },
                      {
                        label: "Started",
                        value: "started",
                      },
                      {
                        label: "Completed",
                        value: "completed",
                      },
                      {
                        label: "Cancelled",
                        value: "cancelled",
                      },
                    ]}
                  >
                    {!rideFetching &&
                      rideData?.data?.data?.docs?.map((item, index) => (
                        <tr>
                          <td>
                            {getSerialNumber(rideData?.data?.data, index)}
                          </td>
                          <td>{item?._id}</td>
                          <td>
                            {item?.createdAt &&
                              format_date(item?.createdAt, "MM/DD/YYYY")}
                          </td>
                          <td>{item?.bookingDetails?.pickupLocation?.name}</td>
                          <td>{item?.bookingDetails?.dropOffLocation?.name}</td>
                          <td>${item?.bookingDetails?.price?.toFixed(2)}</td>
                          <td>
                            {item.adminCommission && `$${item.adminCommission}`}
                          </td>
                          <td>{item.myShare && `$${item.myShare}`}</td>
                          <td>{item?.status}</td>
                        </tr>
                      ))}
                  </Table> */}



                                    <Table
                                        key={"mover-management-table"}
                                        showTableName
                                        tableName={"Muvrr Logs"}
                                        tableTitles={[
                                            "S.No.",
                                            "Full Name",
                                            "Email Address",
                                            "Date Registered",
                                            "Status",
                                            "Action",
                                        ]}
                                        dateFilter
                                        showEntries
                                        sortBy
                                        isLoading={isLoading1}
                                        isFetching={isFetching}
                                        data={data1?.data?.data1?.docs}
                                        totalPages={data1?.data?.data1?.totalPages}
                                        totalDocs={data1?.data?.data1?.totalDocs}
                                        setPage={setPage}
                                        setSearchString={setSearchString}
                                        status={status}
                                        setStatus={setStatus}
                                        perPage={perPage}
                                        setPerPage={setPerPage}
                                        from={from}
                                        setFrom={setFrom}
                                        to={to}
                                        setTo={setTo}
                                    >
                                        {!isFetching &&
                                            data1?.data?.data1?.docs?.map((item, index) => (
                                                <tr>
                                                    <td>{getSerialNumber(data1?.data?.data1, index)}</td>
                                                    <td>{item.firstName}</td>
                                                    <td>{item.email}</td>
                                                    <td>
                                                        {item?.createdAt &&
                                                            format_date(item?.createdAt, "MM/DD/YYYY")}
                                                    </td>
                                                    <td>
                                                        <span className={`status-tag ${item.active ? 'green-tag' : 'red-tag'}`}
                                                        >
                                                            {item.active ? "Active" : "Inactive"}
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <StatusHandler
                                                            key={`${item?._id}-status`}
                                                            view
                                                            active
                                                            item={item}
                                                            // isLoading={loadingStatus}
                                                            redirectUrl={`/business-management/business-details/mover-details/${item?._id}`}
                                                        // onClick={() =>
                                                        //   Confirmation(
                                                        //     `Are you sure you want to ${item?.active ? "inactive" : "active"
                                                        //     }  ${item.firstName}`,
                                                        //     "Yes",
                                                        //     () => mutate(item?._id)
                                                        //   )
                                                        // }
                                                        />
                                                    </td>
                                                </tr>
                                            ))}
                                    </Table>


                                    {/* <div className="row mb-4">
                    <div className="col-12">
                      <div className="back-title">
                        <div className="pageTitleInner">
                          <h1 className="pageTitle text-capitalize mb-0 mt-2">
                            Booking Logs
                          </h1>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-12">
                      <div className="dataTables_length text-start mb-4">
                        <div className="user-listing-top dashCard2 py-4 px-4">
                          <div className="d-xxl-flex align-items-end justify-content-between mb-xxl-3">
                            <div className="userInput mb-3">
                              <div className="d-md-flex align-items-center">
                                <label
                                  htmlFor=""
                                  className="dashLabel mb-3 mb-xxl-0 me-3"
                                >
                                  Sort By Date
                                </label>
                                <div className="d-sm-flex">
                                  <div className="mb-2 mb-xxl-0 me-sm-3">
                                    <input
                                      className="dashInput p-3"
                                      type="date"
                                    />
                                  </div>
                                  <div className="mb-2 mb-xxl-0">
                                    <input
                                      className="dashInput p-3"
                                      type="date"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="userInput mb-2">
                              <div className="form-field mb-3">
                                <div className="d-xxl-flex align-items-center justify-content-end">
                                  <div className="dataTables_filter">
                                    <div className="dashSearchWrap">
                                      <form action="" method="POST">
                                        <input
                                          type="search"
                                          id="search-inp"
                                          className="dashInput search-input w-100"
                                          placeholder="Search"
                                        />
                                        <button
                                          type="submit"
                                          className="search-icon"
                                        >
                                          <i className="fas fa-search" />
                                        </button>
                                      </form>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="d-xxl-flex align-items-end flex-wrap justify-content-between mb-xxl-3">
                            <div className="userInput mb-3">
                              <label className="dashLabel d-inline-block mb-3 mb-xxl-0 me-3">
                                Show
                              </label>
                              <select className="d-inline-block dashInput sm-dropdown">
                                <option value={10}>10</option>
                                <option value={25}>25</option>
                                <option value={50}>50</option>
                                <option value={100}>100</option>
                              </select>
                              <label className="dashLabel d-inline-block mb-3 mb-xxl-0 ms-3">
                                Entries
                              </label>
                            </div>
                            <div className="userInput mb-3">
                              <label className="dashLabel d-inline-block mb-3 mb-xxl-0">
                                Filter By:
                              </label>
                              <select className="d-inline-block dashInput">
                                <option
                                  value=""
                                  disabled=""
                                  hidden=""
                                  selected=""
                                >
                                  All
                                </option>
                                <option value={1}>Active</option>
                                <option value={2}>Inactive</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="maain-tabble table-responsive">
                        <table className="table table-bordered zero-configuration">
                          <thead>
                            <tr>
                              <th>S No.</th>
                              <th>Booking ID</th>
                              <th>Date</th>
                              <th>Pickup Location</th>
                              <th>Drop-Off Location</th>
                              <th>Total Amount</th>
                              <th>Admin Commission</th>
                              <th>My Share</th>
                              <th>Status</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>1</td>
                              <td>123</td>
                              <td>mm/dd/yyyy</td>
                              <td>Abc Location</td>
                              <td>Abc Location</td>
                              <td>$123</td>
                              <td>$123</td>
                              <td>$123</td>
                              <td>Pending</td>
                            </tr>
                            <tr>
                              <td>2</td>
                              <td>123</td>
                              <td>mm/dd/yyyy</td>
                              <td>Abc Location</td>
                              <td>Abc Location</td>
                              <td>$123</td>
                              <td>$123</td>
                              <td>$123</td>
                              <td>On-going</td>
                            </tr>
                            <tr>
                              <td>3</td>
                              <td>123</td>
                              <td>mm/dd/yyyy</td>
                              <td>Abc Location</td>
                              <td>Abc Location</td>
                              <td>$123</td>
                              <td>$123</td>
                              <td>$123</td>
                              <td>Completed</td>
                            </tr>
                            <tr>
                              <td>4</td>
                              <td>123</td>
                              <td>mm/dd/yyyy</td>
                              <td>Abc Location</td>
                              <td>Abc Location</td>
                              <td>$123</td>
                              <td>$123</td>
                              <td>$123</td>
                              <td>Cancelled</td>
                            </tr>
                            <tr>
                              <td>5</td>
                              <td>123</td>
                              <td>mm/dd/yyyy</td>
                              <td>Abc Location</td>
                              <td>Abc Location</td>
                              <td>$123</td>
                              <td>$123</td>
                              <td>$123</td>
                              <td>Accepted</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div className="row align-items-center">
                    <div className="col-sm-12 col-md-5">
                      <div
                        className="showing-result"
                        id="DataTables_Table_0_info"
                      >
                        Showing 10 of 50 Entries
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-7">
                      <div
                        className="dataTables_paginate paging_simple_numbers"
                        id="DataTables_Table_0_paginate"
                      >
                        <ul className="pagination justify-content-end">
                          <li className="paginate_button page-item previous">
                            <button type="button" className="page-link notBtn">
                              Previous
                            </button>
                          </li>
                          <li className="paginate_button page-item active">
                            <button type="button" className="page-link notBtn">
                              01
                            </button>
                          </li>
                          <li className="paginate_button page-item">
                            <button type="button" className="page-link notBtn">
                              02
                            </button>
                          </li>
                          <li className="paginate_button page-item">
                            <button type="button" className="page-link notBtn">
                              03
                            </button>
                          </li>
                          <li className="paginate_button page-item next">
                            <button type="button" className="page-link notBtn">
                              Next
                            </button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div> */}
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    );
}
