import ReactPaginate from "react-paginate";

export default function Pagination({
  currentDocs = 0,
  totalDocs,
  totalPages,
  setPage,
}) {
  return (
    <>
      <div className="col-sm-12 col-md-5">
        <div className="showing-result" id="DataTables_Table_0_info">
          Showing {currentDocs} out of {totalDocs} records
        </div>
      </div>
      <div className="col-sm-12 col-md-7">
        <div
          className="dataTables_paginate paging_simple_numbers"
          id="DataTables_Table_0_paginate"
        >
          <ReactPaginate
            previousLabel={"Previous"}
            nextLabel={"Next"}
            previousClassName={""}
            // previousClassName={
            //   "paginate_button page-item previous page-link disabled"
            // }
            // nextClassName={"paginate_button page-item next page-link disabled"}
            nextClassName={""}
            // breakLabel={"..."}
            // breakClassName={"break-me"}
            pageCount={totalPages}
            marginPagesDisplayed={2}
            pageRangeDisplayed={1}
            onPageChange={(page) => setPage(page.selected + 1)}
            // containerClassName={"pagination"}
            activeClassName={"active"}
            className={
              "pagination paginationMain justify-content-end"
              // "pagination_wrapper d-flex align-items-center justify-content-center"       // will be used in inner div inside ul
            }
            pageLinkClassName={""}
            pageClassName={""}
            // pageClassName={"paginate_button page-item"}
          />
        </div>
      </div>

      {/* <div className="col-sm-12 col-md-5">
        <div className="showing-result" id="DataTables_Table_0_info">
          Showing 10 of 50 Entries
        </div>
      </div>
      <div className="col-sm-12 col-md-7">
        <div
          className="dataTables_paginate paging_simple_numbers"
          id="DataTables_Table_0_paginate"
        >
          <ul className="pagination justify-content-end">
            <li className="paginate_button page-item previous">
              <button type="button" className="page-link notBtn">
                Previous
              </button>
            </li>
            <li className="paginate_button page-item active">
              <button type="button" className="page-link notBtn">
                01
              </button>
            </li>
            <li className="paginate_button page-item">
              <button type="button" className="page-link notBtn">
                02
              </button>
            </li>
            <li className="paginate_button page-item">
              <button type="button" className="page-link notBtn">
                03
              </button>
            </li>
            <li className="paginate_button page-item next">
              <button type="button" className="page-link notBtn">
                Next
              </button>
            </li>
          </ul>
        </div>
      </div> */}
    </>
  );
}
