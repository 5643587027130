const Aboutus = () => {
    return (
        <div className="app-content content dashboard">
            <div className="content-wrapper">
                <div className="content-body">
                    <section id="configuration">
                        <div className="row">
                            <div className="col-12">
                                <h2>About us: </h2>
                                <p>
                                    MUVRRS is committed to providing efficient and reliable same-day delivery services, placing convenience at the forefront. Our aim is to transform the conventional delivery model by embracing technology while keeping the customer experience straightforward and hassle-free.
                                    With a focus on same-day and scheduled deliveries, we strive to simplify your interactions with e-commerce and retail, ensuring your purchases reach you in a timely manner. Upholding values of customer satisfaction, integrity, reliability, and innovation, we are here to assist you. If you have any questions or need support, our customer service team is always ready to help.
                                </p>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    )
}

export default Aboutus