import api from "../Utils/axios";
import { filterParams } from "../Utils/helpers";

export const getAllRides = (
    page,
    perPage,
    search_string,
    status,
    from,
    to,
    user,
    driver
) => {
    return api.get(`/ride/get`, {
        params: filterParams({
            page,
            limit: perPage,
            keyword: search_string,
            status,
            "createdAt[from]": from,
            "createdAt[to]": to,
            user,
            driver,
        }),
    });
};

export const getAllBusinessMuvvrRides = (
    page,
    perPage,
    search_string,
    status,
    from,
    to,
    user,
    // driver,
    id
) => {
    return api.get(`/ride/getBusinessMuvvrRides`, {
        params: filterParams({
            page,
            limit: perPage,
            keyword: search_string,
            status,
            "createdAt[from]": from,
            "createdAt[to]": to,
            user,
            // driver,
            driver_id: id
        }),
    });
};

export const getRideDetails = (id) => {
    return api.get(`/ride/get`, {
        params: {
            _id: id,
        },
    });
};
