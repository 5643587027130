import { useNavigate, useParams } from "react-router-dom";
import { Confirmation } from "../../Components/Modal";
import VehicleDetail from "./VehicleDetail";
import DriverDetail from "./DriverDetail";
import { getRideDetails } from "../../Services/Ride";
import { getReviewByBookingId } from "../../Services/Review";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import Map from "../../Components/Map";
import { format_date } from "../../Utils/helpers";
import { getSetting } from "../../Services/Setting";
import BackButton from "../../Components/BackButton";

export default function RideDetails() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [rideDetail, setRideDetail] = useState({});

  const { data, isLoading, refetch } = useQuery(["ride-details", id], () =>
    getRideDetails(id)
  );
  const { data: settings } = useQuery("get-setting", getSetting);
  // console.log("ride-details: ", data?.data);

  useEffect(() => {
    if (data?.data?.data?.docs?.length > 0)
      setRideDetail(data?.data?.data?.docs?.[0]);
  }, [data?.data?.data?.docs]);

  const {
    data: reviewData,
    isLoading: reviewLoading,
    refetch: reviewRefetch,
  } = useQuery(["booking-review", id], () => getReviewByBookingId(id));
  console.log("booking-review: ",   data);

  return (
    <>
      <div className="app-content content dashboard">
        <div className="content-wrapper">
          <div className="content-body">
            {/* Basic form layout section start */}
            <section id="configuration">
              <div className="row">
                <div className="col-12">
                  <div className="card-content collapse show dashCard pt-5 px-5">
                    <div className="row mb-4">
                      <div className="col-12">
                        <div className="back-title">
                          <div className="pageTitleInner d-flex align-items-baseline">
                            <h1 className="pageTitle text-capitalize m-0">
                              <BackButton />
                            Ride Details
                            </h1>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row dashCard2 flex-column-reverse flex-xxl-row mb-4 px-4 py-3">
                      <div className="col-xxl-6">
                        <p className="rideTime my-2">
                          {/* Booking for Aug 30, 2021, 00:00 PM */}
                          {rideDetail?.createdAt &&
                            `Booking for ${format_date(
                              rideDetail?.createdAt,
                              "MMM DD, YYYY, hh:mm A"
                            )}`}
                        </p>
                      </div>
                      <div className="col-xxl-6">
                        <div className="text-end">
                          <div className="statusBox my-2">
                            <p className="m-0">
                              Status:{" "}
                              <span style={{ textTransform: "capitalize" }}>
                                {rideDetail?.status}
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row mb-4">
                      <div className="col-12 my-2">
                        <div className="d-flex align-items-baseline">
                          <h1 className="pageTitle text-capitalize m-0">
                            {rideDetail?.user?.firstName}
                          </h1>
                          <span className="mx-2">
                            {rideDetail?._id && `#${rideDetail?._id}`}
                          </span>
                        </div>
                      </div>
                      <div className="col-12 my-2">
                        <Map
                          originLat={
                            rideDetail?.bookingDetails?.pickupLocation?.lat
                          }
                          originLng={
                            rideDetail?.bookingDetails?.pickupLocation?.lng
                          }
                          destinationLat={
                            rideDetail?.bookingDetails?.dropOffLocation?.lat
                          }
                          destinationLng={
                            rideDetail?.bookingDetails?.dropOffLocation?.lng
                          }
                        // originLat={24.859392495364922}
                        // originLng={67.05242870873693}
                        // destinationLat={24.859806296562997}
                        // destinationLng={67.0582772654939}
                        />
                        {/* <iframe
                          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d50470.02846874147!2d-122.47261937941492!3d37.75776267828957!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80859a6d00690021%3A0x4a501367f076adff!2sSan%20Francisco%2C%20CA%2C%20USA!5e0!3m2!1sen!2s!4v1649050746012!5m2!1sen!2s"
                          width="100%"
                          height="400px"
                          style={{ border: 0 }}
                          allowFullScreen=""
                          loading="lazy"
                          referrerPolicy="no-referrer-when-downgrade"
                        /> */}
                      </div>
                      <div className="dashCard2 px-4 py-3 mt-3">
                        <div className="row">
                          <div className="col-lg-4">
                            <div className="locationBox">
                              <i className="fas fa-clock locationIcon accentColor mt-1" />
                              <div className="locationDetail">
                                <h4>Pick-up Location</h4>
                                <p className="mb-2">
                                  {
                                    rideDetail?.bookingDetails?.pickupLocation
                                      ?.name
                                  }
                                </p>
                                <p className="mb-0">Pickup time: <span className="fw-bold text-black">03:00 PM</span></p>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-4">
                            <div className="locationBox">
                              <i className="fas fa-map-marker-alt locationIcon accentColor mt-1" />
                              <div className="locationDetail">
                                <h4>Drop-off Location</h4>
                                <p className="mb-2">
                                  {
                                    rideDetail?.bookingDetails?.dropOffLocation
                                      ?.name
                                  }
                                </p>
                                <p className="mb-0">Drop-off time: <span className="fw-bold text-black">06:00 PM</span></p>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-4">
                            <div className="locationBox">
                              <i className="fas fa-cube locationIcon accentColor mt-1" />
                              <div className="locationDetail">
                                <h4>Drop-off Location</h4>
                                <p>
                                  {
                                    rideDetail?.bookingDetails?.dropOffLocation
                                      ?.name
                                  }
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row mb-4">
                      <div className="col-xxl-10">
                        <div className="packageDetailBox my-3">
                          <h5 className="packageHeading fw-bold text-gray">Item</h5>
                          <p
                            className="items-count"
                            style={{ textTransform: "capitalize" }}
                          >
                            Beds, Sofa, Dining Table
                            {/* {rideDetail?.bookingDetails?.package?.item?.length >
                              0 &&
                              rideDetail?.bookingDetails?.package?.item?.map(
                                (item) => item
                              )} */}
                          </p>
                          <div className="mainDetail d-flex align-items-baseline flex-wrap my-1">
                            {/* {rideDetail?.bookingDetails?.package?.images
                              ?.length > 0 &&
                              rideDetail?.bookingDetails?.package?.images?.map(
                                (item) => (
                                  <img
                                    src={item?.imageUrl}
                                    alt=""
                                    className="mainImage"
                                  />
                                )
                              )} */}
                            <img
                              src="images/package1.png"
                              alt=""
                              className="mainImage"
                            />
                            <img
                              src="images/package2.png"
                              alt=""
                              className="mainImage"
                            />
                            <img
                              src="images/package3.png"
                              alt=""
                              className="mainImage"
                            />
                            <img
                              src="images/package4.png"
                              alt=""
                              className="mainImage"
                            />
                          </div>
                        </div>
                        <div className="d-flex gap-5">
                          <div className="packageDetailBox my-3">
                            <h5 className="packageHeading fw-bold text-gray">Load Weight</h5>
                            <p className="weight">
                              {rideDetail?.bookingDetails?.package?.weight &&
                                `${rideDetail?.bookingDetails?.package?.weight}lbs`}
                            </p>
                          </div>
                          <div className="packageDetailBox my-3">
                            <h5 className="packageHeading fw-bold text-gray">Cost</h5>
                            <p className="cost">
                              {rideDetail?.bookingDetails?.price &&
                                `$${rideDetail?.bookingDetails?.price?.toFixed(
                                  2
                                )}`}
                            </p>
                          </div>
                        </div>
                        {/* <div className="d-flex gap-5">
                          <div className="packageDetailBox my-3">
                            <h5 className="packageHeading fw-bold">Admin Commission</h5>
                            <p className="">
                              {rideDetail?.bookingDetails?.price &&
                                `$ ${(rideDetail?.bookingDetails?.price?.toFixed(2) - (rideDetail?.bookingDetails?.price?.toFixed(2) - ((rideDetail?.bookingDetails?.price?.toFixed(2) * Number(settings?.data?.data?.adminCommission)) / 100))).toFixed(2)}`}
                            </p>
                          </div>
                          
                          
                          <div className="packageDetailBox my-3">
                            <h5 className="packageHeading fw-bold">Total Cost</h5>
                            <p className="">
                              {rideDetail?.bookingDetails?.price &&
                                `$${rideDetail?.bookingDetails?.price?.toFixed(
                                  2
                                )}`}
                            </p>
                          </div>
                        </div> */}
                      </div>
                    </div>
                    <div className="row mb-4">
                      <div className="col-lg-12 col-xxl-4 d-flex ride-detail-box">
                        <div className="dashCard2 py-4 px-4 flex-grow-1">
                          <div className="ride-detail-header">
                            <p className="mainText2 fw-medium mb-3">Vehicle Information</p>
                            <h3 className="packageDetail accentColor mb-4 text-capitalize fw-semibold">{rideDetail?.driver?.vehicle?.name}</h3>
                          </div>
                          {rideDetail?.driver?.vehicle?.type?.name &&
                          <>
                          <div className="row mt-3">
                            <div className="col-lg-6">
                              <h5 className="mainLabel mb-0">Vehicle Type:</h5>
                            </div>
                            <div className="col-lg-6">
                              <p className="mainText mb-0">{rideDetail?.driver?.vehicle?.type?.name}</p>
                            </div>
                          </div>
                          </>
                          }
                          <div className="row mt-3">
                            <div className="col-lg-6">
                              <h5 className="mainLabel mb-0">Vehcile Number:</h5>
                            </div>
                            <div className="col-lg-6">
                              <p className="mainText mb-0">ABC-001</p>
                            </div>
                          </div>
                          <div className="row mt-3">
                            <div className="col-lg-6">
                              <h5 className="mainLabel mb-0">Brand Name:</h5>
                            </div>
                            <div className="col-lg-6">
                              <p className="mainText mb-0">Abc</p>
                            </div>
                          </div>
                          {
                          rideDetail?.driver?.vehicle?.color &&
                          <>
                          <div className="row mt-3">
                            <div className="col-lg-6">
                              <h5 className="mainLabel mb-0">Vehicle Color:</h5>
                            </div>
                            <div className="col-lg-6">
                              <p className="mainText mb-0">{rideDetail?.driver?.vehicle?.color}</p>
                            </div>
                          </div>
                          </>
                          }
                          <div className="row mt-3">
                            <div className="col-lg-6">
                              <h5 className="mainLabel mb-0">Date Registered:</h5>
                            </div>
                            <div className="col-lg-6">
                              <p className="mainText mb-0">mm/dd/yy</p>
                            </div>
                          </div>
                          {
                          rideDetail?.driver?.vehicle?.numberPlate &&
                          <>
                          <div className="row mt-3">
                            <div className="col-lg-6">
                              <h5 className="mainLabel mb-0">Plate No:</h5>
                            </div>
                            <div className="col-lg-6">
                              <p className="mainText mb-0">{rideDetail?.driver?.vehicle?.numberPlate}</p>
                            </div>
                          </div>
                          </>
                          }
                          <div className="row mt-3">
                            <div className="col-12 delivered-images d-flex flex-wrap gap-3">
                              {
                              rideDetail?.deliveredImages?.map(item => (
                                <img
                                  src={
                                    item?.imageUrl ??
                                    "images/mover.png"
                                  }
                                  alt=""
                                  className="img-fluid moverImg"
                                />
                              ))
                              }
                            </div>
                          </div>
                          <div className="text-end mt-3">
                            <a
                              type="button"
                              data-bs-toggle="modal"
                              data-bs-target="#vehicleDetails"
                              className="notBtn accentColor d-block w-100 text-decoration-underline"
                            >
                              View Details
                            </a>
                          </div>
                        </div>
                      </div>

                      {rideDetail?.status !== "cancelled" &&
                      <>
                      <div className="col-lg-12 col-xxl-4 d-flex ride-detail-box">
                        <div className="dashCard2 py-4 px-4 flex-grow-1">
                          <div className="ride-detail-header d-flex">
                            <h3 className="packageDetail accentColor mb-0s text-capitalize fw-semibold align-self-center">Fare</h3>
                          </div>
                          {rideDetail?.bookingDetails?.price &&
                          <>
                          <div className="row mt-3">
                            <div className="col-lg-6">
                              <h5 className="mainLabel mb-0">Base Fare:</h5>
                            </div>
                            <div className="col-lg-6">
                              <p className="mainText mb-0 fw-bold text-black text-lg-end">${Number(settings?.data?.data?.baseFare)}</p>
                            </div>
                          </div>
                          </>
                          }
                          <div className="row mt-3">
                            <div className="col-lg-6">
                              <h5 className="mainLabel mb-0">Per Mile Fare:</h5>
                            </div>
                            <div className="col-lg-6">
                              <p className="mainText mb-0 fw-bold text-black text-lg-end">$10</p>
                            </div>
                          </div>
                          <div className="row mt-3">
                            <div className="col-lg-6">
                              <h5 className="mainLabel mb-0">Total Miles:</h5>
                            </div>
                            <div className="col-lg-6">
                              <p className="mainText mb-0 fw-bold text-black text-lg-end">2 Miles</p>
                            </div>
                          </div>
                          <div className="row mt-3">
                            <div className="col-lg-6">
                              <h5 className="mainLabel mb-0">Excessive Weight:</h5>
                            </div>
                            <div className="col-lg-6">
                              <p className="mainText mb-0 fw-bold text-black text-lg-end">10 Pounds</p>
                            </div>
                          </div>
                          <div className="row mt-3">
                            <div className="col-lg-6">
                              <h5 className="mainLabel mb-0">Extra Cost:</h5>
                            </div>
                            <div className="col-lg-6">
                              <p className="mainText mb-0 fw-bold text-black text-lg-end">$200</p>
                            </div>
                          </div>
                          {rideDetail?.bookingDetails?.fragile &&
                          <>
                          <div className="row mt-3">
                            <div className="col-lg-6">
                              <h5 className="mainLabel mb-0">Fragile Cost</h5>
                            </div>
                            <div className="col-lg-6">
                              <p className="mainText mb-0 fw-bold text-black text-lg-end">${Number(settings?.data?.data?.fragileCost)}</p>
                            </div>
                          </div>
                          </>
                          }
                          {rideDetail?.bookingDetails?.price &&
                          <>
                          <div className="row mt-3">
                            <div className="col-lg-6">
                              <h5 className="mainLabel mb-0">Total:</h5>
                            </div>
                            <div className="col-lg-6">
                              <p className="mainText mb-0 fw-bold text-black text-lg-end">${rideDetail?.bookingDetails?.price?.toFixed(2)}</p>
                            </div>
                          </div>
                          </>
                          }
                        </div>
                      </div>
                      </>
                      }

                      
                      <div className="col-lg-12 col-xxl-4 d-flex ride-detail-box">
                        <div className="dashCard2 py-3 px-4 flex-grow-1">
                          <div className="ride-detail-header d-flex">
                            <p className="mainText2 fw-medium mb-2 align-self-center">Mover Details</p>
                          </div>
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <p
                                className="packageDetail accentColor mb-0"
                                style={{ textTransform: "capitalize" }}
                              >
                                {rideDetail?.driver?.firstName &&
                                  `${rideDetail?.driver?.firstName}`}
                              </p>
                              <p className="rating mb-0">
                              <i className="fa-star fas goldColor mx-1" />4.5
                            </p>
                            </div>
                            <div className="flex-shrink-0">
                              <img
                                src={
                                  rideDetail?.driver?.profileImage?.imageUrl ??
                                  "images/mover.png"
                                }
                                alt=""
                                className="img-fluid mb-2 moverImg"
                              />
                            </div>
                          </div>

                          <div className="row mt-3">
                            <div className="col-lg-6">
                              <h5 className="mainLabel mb-0">Email Address:</h5>
                            </div>
                            <div className="col-lg-6">
                              <p className="mainText mb-0 text-lg-end">{rideDetail?.driver?.email}</p>
                            </div>
                          </div>
                          <div className="row mt-3">
                            <div className="col-lg-6">
                              <h5 className="mainLabel mb-0">Phone Number:</h5>
                            </div>
                            <div className="col-lg-6">
                              <p className="mainText mb-0 text-lg-end">000-00-0000</p>
                            </div>
                          </div>
                          <div className="row mt-3">
                            <div className="col-lg-6">
                              <h5 className="mainLabel mb-0">Date Registered:</h5>
                            </div>
                            <div className="col-lg-6">
                              <p className="mainText mb-0 text-lg-end">mm/dd/yy</p>
                            </div>
                          </div>
                          <div className="row mt-3">
                            <div className="col-lg-6">
                              <h5 className="mainLabel mb-0">License Number:</h5>
                            </div>
                            <div className="col-lg-6">
                              <p className="mainText mb-0 text-lg-end">124124141</p>
                            </div>
                          </div>
                          <div className="row mt-3">
                            <div className="col-lg-6">
                              <h5 className="mainLabel mb-0">Number of Deliveries Completed Successfully:</h5>
                            </div>
                            <div className="col-lg-6">
                              <p className="mainText mb-0 text-lg-end">000</p>
                            </div>
                          </div>
                          <div className="row mt-3">
                            <div className="col-lg-6">
                              <h5 className="mainLabel mb-0">License:</h5>
                            </div>
                            <div className="col-lg-6">
                              <div className="licence-wrap d-flex flex-grow-1 gap-3 flex-xl-row flex-column">
                                <div className="licence-img">
                                  <img
                                    src={
                                      (rideDetail?.driver?.licenseImageFront?.imageUrl) ??
                                      "images/license.png"
                                    }
                                    alt=""
                                    className="img-fluid"
                                  />
                                </div>
                                <div className="licence-img">
                                  <img
                                    src={
                                      (rideDetail?.driver?.licenseImageBack?.imageUrl) ??
                                      "images/license.png"
                                    }
                                    alt=""
                                    className="img-fluid"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="text-end mt-3">
                            <a
                              type="button"
                              data-bs-toggle="modal"
                              data-bs-target="#driverDetails"
                              className="notBtn accentColor d-block w-100 text-decoration-underline"
                            >
                              View Details
                            </a>
                          
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div className="row">
                      <div className="dashCard2 d-flex justify-content-between flex-grow-1 gap-5 py-3 px-4">
                        <div className="packageDetail1 flex-shrink-0">
                          <p className="mainText2 fw-bold mb-2">
                            Delivery Details
                          </p>
                          <h6 className="fw-bold mb-2">
                            Delivery Images
                          </h6>
                          {
                            rideDetail?.deliveredImages?.map(item => (
                              <img
                                src={
                                  item?.imageUrl ??
                                  "images/mover.png"
                                }
                                alt=""
                                className="img-fluid mb-2 moverImg mx-2"
                              />
                            ))
                          }
                          <h6 className="fw-bold mb-2">
                            Driver Signature
                          </h6>
                          <img
                            src={
                              rideDetail?.signature?.imageUrl ??
                              "images/mover.png"
                            }
                            alt=""
                            className="img-fluid mb-2 moverImg"
                          />
                        </div>
                      </div>
                    </div> */}
                    <div>
                      {/* {reviewData?.data?.data !== null && (
                        <div className="dashCard2 flex-grow-1 py-3 px-4">
                          <div className="packageDetail1 flex-shrink-0">
                            <p className="mainText2 mb-2">User Review</p>
                            <span>
                              {reviewData?.data?.data?.rating > 0 &&
                                new Array(reviewData?.data?.data?.rating)
                                  ?.fill(5)
                                  ?.map(() => (
                                    <i className="fa-star fas goldColor mx-1" />
                                  ))}
                              
                            </span>
                            <p>{reviewData?.data?.data?.message}</p>
                          </div>
                        </div>
                      )} */}
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
      <VehicleDetail data={rideDetail?.driver} />
      <DriverDetail data={rideDetail?.driver} />
    </>
  );
}
