export default function List({ item, handleTabClick, chatTab }) {
  console.log("item ", item?.user?.role);

  return (
    <li
      className={`d-flex align-items-center ${chatTab === item._id ? "active" : ""
        }`}
    >
      <a
        data-toggle="tab"
        role="tab"
        // onClick={() => handleTabClick("chat-1")}
        onClick={() => handleTabClick(item._id)}
        className="media d-flex w-100 position-relative"
      >
        <div className="media-left flex-shrink-0 text-center me-3 align-self-start">
          <img
            src={item?.user?.profileImage?.imageUrl ?? "images/userImage.png"}
            alt=""
          />
          {/* <!-- <i className="fas fa-user-circle"></i> --> */}
        </div>
        <div className="media-body align-self-center flex-grow-1">
          <div className="meta d-flex align-items-center">
            <h2>{item?.user?.role === 'business' ? item?.user?.companyName : item?.user?.firstName}</h2>
            {/* <span className="contact-status online green"></span> */}
            {/* <div className="badge badge-danger">01</div>
            <div className="badge badge-warning">01</div> */}
          </div>
          {/* <div className="short-message">
            <h3>recent Message</h3>
          </div> */}
        </div>
      </a>
    </li>
  );
}
