import api from "../Utils/axios";
import { filterParams } from "../Utils/helpers";

export const getReports = (page, perPage, search_string, from, to) => {
    return api.get(`/ride/get-reports`, {
        params: filterParams({
            page,
            limit: perPage,
            keyword: search_string,
            "createdAt[from]": from,
            "createdAt[to]": to,
        }),
    });
};

export const getReportDetails = (id) => {
    return api.get(`/ride/get-reports`, {
        params: {
            _id: id,
        },
    });
};
