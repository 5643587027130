import { CChart } from "@coreui/react-chartjs";

export default function LineChart({ data = [] }) {
  // const data = [0, 0, 0, 3, 0, 5, 7, 8, 0, 0, 1, 1];
  return (
    <CChart
      type="line"
      height="90"
      options={{
        scales: {
          y: {
            suggestedMin: 0,
            suggestedMax: 40,
          },
        },
      }}
      data={{
        labels: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
        tension: "0.5",
        datasets: [
          {
            label: "Movers",
            fill: true,
            backgroundColor: "#fd7e14",
            borderColor: "#fd7e14",
            pointBackgroundColor: "#fd7e14",
            pointBorderColor: "#fff",
            borderWidth: 1,
            data,
            tension: 0.5,
          },
        ],
      }}
    />
  );
}
