import api from "../Utils/axios";
// import { filterParams } from "../Utils/helpers";

export const getSetting = () => {
  return api.get(`/settings/get`);
};

export const editSetting = (id, data) => {
  return api.post(`/settings/edit/${id}`, data);
};
