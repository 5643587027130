import { useNavigate } from "react-router-dom";
import { vehicleTypeSchema } from "../../Schema/generalSchema";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Button from "../../Components/Button";
import { useMutation } from "react-query";
import { addVehicleType } from "../../Services/VehicleType";
import { Error, Success } from "../../Components/Modal";
import BackButton from "../../Components/BackButton";

export default function AddVehicleType() {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    resolver: yupResolver(vehicleTypeSchema),
  });
  const statusTypeOptions = [
    { value: "", label: "Select" },
    { value: true, label: "Active" },
    { value: false, label: "Inactive" },
  ];

  const { mutate, isLoading: loadingStatus } = useMutation(
    (data) => addVehicleType(data),
    {
      onSuccess: (res) => {
        Success("Vehicle Type", res.data?.message);
        navigate("/vehicle-type-management");
      },
      onError: (err) => {
        Error(err?.response?.data?.message);
      },
    }
  );

  const submit = (data) => {
    // console.log(data, "data");
    // console.log({
    //   name: data?.name,
    //   baseFare: Number(data?.cost),
    //   active: JSON.parse(data?.status),
    // });

    mutate({
      name: data?.name,
      baseFare: Number(data?.cost),
      active: JSON.parse(data?.status),
    });
  };

  // console.log(errors);

  return (
    <div className="app-content content dashboard">
      <div className="content-wrapper">
        <div className="content-body">
          {/* Basic form layout section start */}
          <section id="configuration">
            <div className="row">
              <div className="col-12">
                <div className="card-content collapse show dashCard pt-5 px-5">
                  <div className="row mb-4">
                    <div className="col-xl-6">
                      <div className="back-title">
                        <div className="pageTitleInner d-flex align-items-baseline">
                          <h1 className="pageTitle text-capitalize m-0 mt-1">
                          <BackButton />
                            Add New Type
                          </h1>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row py-4 px-4 mb-3">
                    <div className="col-12">
                      <div className="row mb-4">
                        <div className="col-xxl-6">
                          {/* <form action="vehicle-type-management.php"> */}
                          <form onSubmit={handleSubmit(submit)}>
                            <div className="mainDetail my-3">
                              <label htmlFor="" className="mainLabel">
                                Type Name<span className="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                className="dashInput search-input w-100"
                                placeholder="Enter Name of the Truck Type"
                                {...register("name")}
                              />
                              <span className="text-danger">
                                {errors?.name?.message}
                              </span>
                            </div>
                            <div className="mainDetail my-3">
                              <label htmlFor="" className="mainLabel">
                                Type Cost<span className="text-danger">*</span>
                              </label>
                              <input
                                type="number"
                                className="dashInput search-input w-100"
                                placeholder="Enter Cost of the Truck Type"
                                {...register("cost")}
                              />
                              <span className="text-danger">
                                {errors?.cost?.message}
                              </span>
                            </div>
                            <div className="mainDetail my-3">
                              <label htmlFor="" className="mainLabel">
                                Status<span className="text-danger">*</span>
                              </label>
                              <Controller
                                name="status"
                                render={({ field }) => (
                                  <select
                                    name=""
                                    id=""
                                    className="dashInput search-input w-100"
                                    {...field}
                                  >
                                    {statusTypeOptions?.length > 0 &&
                                      statusTypeOptions?.map((item) => (
                                        <option value={item.value}>
                                          {item.label}
                                        </option>
                                      ))}
                                  </select>
                                )}
                                control={control}
                              />
                              <span className="text-danger">
                                {errors?.status?.message}
                              </span>
                            </div>
                            <div className="mainDetail text-end my-3">
                              <Button
                                className="siteBtn"
                                type="submit"
                                loading={loadingStatus}
                                // onClick={() => navigate(-1)}
                              >
                                Save
                              </Button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}
