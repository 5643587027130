import { useQuery } from "react-query";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { getProfile } from "../../Services/Profile";
import { useEffect } from "react";
import { setUser } from "../../Redux/slice";

export default function Profile() {
  const dispatch = useDispatch();

  const { isLoading, data } = useQuery("getProfile", getProfile);
  // console.log("getProfile Data", data?.data);

  useEffect(() => {
    if (data?.data?.user) {
      dispatch(setUser(data?.data?.user));
    }
  }, [data?.data?.user]);

  return (
    <div className="app-content content dashboard">
      <div className="content-wrapper">
        <div className="content-body">
          {/* Basic form layout section start */}
          <section id="configuration">
            <div className="row">
              <div className="col-12">
                <div className="card-content collapse show dashCard pt-5 px-5">
                  <div className="row mb-4">
                    <div className="col-xl-6">
                      <div className="back-title">
                        <div className="pageTitleInner d-flex align-items-baseline">
                          <h1 className="pageTitle text-capitalize m-0 mt-1">
                            Profile
                          </h1>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row py-4 px-4 mb-3">
                    <div className="col-xxl-6 mb-4">
                      <div className="dashCard2 py-3 px-4">
                        <div className="mainDetail text-center my-3">
                          <div className="userIimageFrame mx-auto my-3">
                            <img
                              src={
                                data?.data?.user?.profileImage?.imageUrl ??
                                "images/avatar.png"
                              }
                              alt=""
                              className="userImage img-fluid"
                            />
                          </div>
                          <Link
                            // href="edit-profile.php"
                            to="/profile/edit"
                            className="accentColor text-decoration-underline"
                          >
                            Edit Profile
                          </Link>
                        </div>
                        <div className="mainDetail d-xl-flex align-items-baseline gap-5 my-3">
                          <label htmlFor="" className="mainLabel">
                            Name:
                          </label>
                          {data?.data?.user?.firstName && (
                            <p className="mainText">
                              {data?.data?.user?.firstName}
                            </p>
                          )}
                        </div>
                        {/* <div className="mainDetail d-xl-flex align-items-baseline gap-5 my-3">
                          <label htmlFor="" className="mainLabel">
                            Last Name:
                          </label>
                          <p className="mainText">Adams</p>
                        </div> */}
                        <div className="mainDetail d-xl-flex align-items-baseline gap-5 my-3">
                          <label htmlFor="" className="mainLabel">
                            Email Address:
                          </label>
                          {data?.data?.user?.email && (
                            <p className="mainText">
                              {data?.data?.user?.email}
                            </p>
                          )}
                        </div>
                        <div className="mainDetail text-center my-3 mt-5">
                          <Link
                            //  href="change-password.php"
                            to={"/profile/change-password"}
                            className="siteBtn"
                          >
                            Change Password
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}
