import { Link, useNavigate, useParams } from "react-router-dom";
import { Confirmation, Error, Reason, Success } from "../../Components/Modal";
import { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import {
    changeVehicleRequestStatus,
    getVehicleRequestDetails,
} from "../../Services/VehicleRequest";
import { format_date } from "../../Utils/helpers";
import Button from "../../Components/Button";
import BackButton from "../../Components/BackButton";

export default function VehicleDetails() {
    const navigate = useNavigate();
    const { id } = useParams();
    const [requestDetail, setRequestDetail] = useState({});

    const { data, isLoading, refetch } = useQuery(
        ["vehicleRequest_details", id],
        () => getVehicleRequestDetails(id)
    );
    // console.log("vehicleRequest_details: ", data?.data);

    useEffect(() => {
        if (data?.data?.data?.docs?.length > 0)
            setRequestDetail(data?.data?.data?.docs?.[0]);
    }, [data?.data?.data?.docs]);

    const { mutate, isLoading: loadingStatus } = useMutation(
        (data) => changeVehicleRequestStatus(id, data),
        {
            onSuccess: (res) => {
                refetch();
                Success("Vehicle Request Status", res.data?.message);
            },
            onError: (err) => {
                Error(err?.response?.data?.message);
            },
        }
    );

    console.log(requestDetail, "requestDetail");
    return (
        <div className="app-content content dashboard">
            <div className="content-wrapper">
                <div className="content-body">
                    {/* Basic form layout section start */}
                    <section id="configuration">
                        <div className="row">
                            <div className="col-12">
                                <div className="card-content collapse show dashCard pt-5 px-5">
                                    <div className="row mb-4">
                                        <div className="col-12 d-flex justify-content-between flex-column flex-sm-row align-items-start gap-3">
                                            <div className="back-title">
                                                <div className="pageTitleInner d-flex align-items-baseline">
                                                    <h1 className="pageTitle text-capitalize m-0">
                                                        <BackButton />
                                                        Vehicle Details
                                                    </h1>
                                                </div>

                                            </div>
                                            <div className="statusBox">
                                                <p className="m-0">
                                                    Status:{" "}
                                                    <span>
                                                        {requestDetail?.status === "pending"
                                                            ? "Pending"
                                                            : requestDetail?.status === false && "Rejected"}
                                                    </span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="dashCard2 mb-5">
                                        <div className="py-4 px-4">
                                            <div className="user-details pb-5">
                                                <div className="mainDetail d-lg-flex align-items-baseline mt-md-3">
                                                    <label htmlFor="" className="mainLabel mb-0">Muvrr Full Name:</label>
                                                    <p className="mainText mb-0">{requestDetail?.user?.firstName}</p>
                                                </div>
                                                <div className="mainDetail d-lg-flex align-items-baseline mt-md-3">
                                                    <label htmlFor="" className="mainLabel mb-0">Vehicle Name:</label>
                                                    <p className="mainText mb-0">{requestDetail?.name}</p>
                                                </div>
                                                <div className="mainDetail d-lg-flex align-items-baseline mt-md-3">
                                                    <label htmlFor="" className="mainLabel mb-0">Vehicle Brand:</label>
                                                    <p className="mainText mb-0">{requestDetail?.brandName}</p>
                                                </div>
                                                <div className="mainDetail d-lg-flex align-items-baseline mt-md-3">
                                                    <label htmlFor="" className="mainLabel mb-0">Vehicle Color:</label>
                                                    <p className="mainText mb-0">{requestDetail?.color}</p>
                                                </div>
                                                <div className="mainDetail d-lg-flex align-items-baseline mt-md-3">
                                                    <label htmlFor="" className="mainLabel mb-0">Plate No:</label>
                                                    <p className="mainText mb-0">{requestDetail?.numberPlate}</p>
                                                </div>
                                                <div className="mainDetail d-lg-flex align-items-baseline mt-md-3">
                                                    <label htmlFor="" className="mainLabel mb-0">Date:</label>
                                                    <p className="mainText mb-0">
                                                        {requestDetail?.createdAt &&
                                                            format_date(
                                                                requestDetail?.createdAt,
                                                                "DD/MM/YYYY"
                                                            )}
                                                    </p>
                                                </div>
                                                <div className="mainDetail d-lg-flex align-items-baseline mt-md-3">
                                                    <label htmlFor="" className="mainLabel mb-0">Vehicle Type:</label>
                                                    <p className="mainText mb-0">{requestDetail?.type?.name}</p>
                                                </div>
                                                <div className="mainDetail d-lg-flex align-items-baseline mt-md-3">
                                                    <label htmlFor="" className="mainLabel mb-0">Troller Size:</label>
                                                    <p className="mainText mb-0">Medium Trailer</p>
                                                </div>
                                                <div className="mainDetail d-lg-flex align-items-baseline mt-md-3">
                                                    <label htmlFor="" className="mainLabel mb-0">Specifications:</label>
                                                    <p className="mainText mb-0">Medium Trailer</p>
                                                </div>
                                                <div className="mainDetail d-lg-flex align-items-baseline mt-md-3">
                                                    <label htmlFor="" className="mainLabel mb-0">Accessories:</label>
                                                    <p className="mainText mb-0">Tie Down Straps</p>
                                                </div>
                                                <div className="mainDetail d-lg-flex align-items-baseline mt-md-3">
                                                    <label htmlFor="" className="mainLabel mb-0">GVWR:</label>
                                                    <p className="mainText mb-0">2000 KG</p>
                                                </div>
                                                <div className="row mt-4">
                                                    <div className="col-md-12 col-lg-8 col-xxl-6">
                                                        <div className="vehicle-wrap d-flex align-items-baseline flex-wrap my-1 gap-4">
                                                            {
                                                                requestDetail?.vehicleImage?.map(item => (
                                                                    <div className="vehicle-img">
                                                                        <img
                                                                            src={
                                                                                item?.imageUrl ??
                                                                                "images/truck1.png"
                                                                            }
                                                                            alt=""
                                                                        />
                                                                    </div>
                                                                ))
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    );
}
