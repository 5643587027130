import { Link, useNavigate } from "react-router-dom";
import { Confirmation, Reason } from "../../Components/Modal";
import Table from "../../Components/Table";
import StatusHandler from "../../Components/Table/StatusHandler";
import useTableControls from "../../Hooks/useTableControls";
import useFetchData from "../../Hooks/useFetchData";
import { getSignupBusinessRequests } from "../../Services/SignupRequest";
import { format_date, getSerialNumber } from "../../Utils/helpers";
import BackButton from "../../Components/BackButton";

export default function BusinessRequests() {
    const navigate = useNavigate();

    // const sampleData = [
    //   {
    //     sn: "1",
    //     name: "Mark Carson",
    //     email: "abc@xyz.com",
    //     date: "01/02/2022",
    //     phone: "+1 12 213 0",
    //     requestApprovalStatus: "pending",
    //   },
    //   {
    //     sn: "2",
    //     name: "Mark Carson",
    //     email: "abc@xyz.com",
    //     date: "01/02/2022",
    //     phone: "+1 12 213 0",
    //     requestApprovalStatus: "accepted",
    //   },
    //   {
    //     sn: "3",
    //     name: "Mark Carson",
    //     email: "abc@xyz.com",
    //     date: "01/02/2022",
    //     phone: "+1 12 213 0",
    //     requestApprovalStatus: "pending",
    //   },
    //   {
    //     sn: "4",
    //     name: "Mark Carson",
    //     email: "abc@xyz.com",
    //     date: "01/02/2022",
    //     phone: "+1 12 213 0",
    //     requestApprovalStatus: "pending",
    //   },
    // ];

    const {
        perPage,
        setPerPage,
        status,
        setStatus,
        search_string,
        setSearchString,
        from,
        setFrom,
        to,
        setTo,
    } = useTableControls();

    const {
        // INTERNAL EXPORTS
        setPage,
        // REACT QUERY EXPORTS
        isFetching,
        isLoading,
        data,
        refetch,
    } = useFetchData("signupRequest_logs", getSignupBusinessRequests, [
        perPage,
        search_string,
        "pending",
        from,
        to,
    ]);
    // console.log("signupRequest_logs Data: ", data?.data);

    return (
        <div className="app-content content dashboard">
            <div className="content-wrapper">
                <div className="content-body">
                    {/* Basic form layout section start */}
                    <section id="configuration">
                        <div className="row">
                            <div className="col-12">
                                <div className="card-content collapse show dashCard pt-5 px-5">
                                    <div className="row mb-4">
                                        <div className="col-12">
                                            <div className="back-title">
                                                <div className="pageTitleInner d-flex align-items-baseline">
                                                    <h1 className="pageTitle text-capitalize m-0">
                                                        <BackButton />
                                                        Business Requests
                                                    </h1>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    {/* <div class="col-12">
                                        <div class="dataTables_length text-start mb-4">
                                            <div class="user-listing-top dashCard2 py-4 px-4">
                                                <div class="d-xxl-flex align-items-end justify-content-between mb-xxl-3">
                                                    <div class="userInput mb-3">
                                                        <div class="d-md-flex align-items-center">
                                                            <label for="" class="dashLabel mb-3 mb-xxl-0 me-3"
                                                            >Sort By Date</label
                                                            >
                                                            <div class="d-sm-flex">
                                                                <div class="mb-2 mb-xxl-0 me-sm-3">
                                                                    <input class="dashInput p-3" type="date" value="" />
                                                                </div>
                                                                <div class="mb-2 mb-xxl-0">
                                                                    <input class="dashInput p-3" type="date" value="" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="userInput mb-2">
                                                        <div class="form-field mb-3">
                                                            <div class="d-xxl-flex align-items-center justify-content-end">
                                                                <div class="dataTables_filter">
                                                                    <div class="dashSearchWrap">
                                                                        <input
                                                                            type="search"
                                                                            id="search-inp"
                                                                            class="dashInput search-input w-100"
                                                                            placeholder="Search"
                                                                            value=""
                                                                        /><button class="search-icon">
                                                                            <i class="fas fa-search"></i>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    class="d-xxl-flex align-items-end flex-wrap justify-content-between mb-xxl-3"
                                                >
                                                    <div class="userInput mb-3">
                                                        <label class="dashLabel d-inline-block mb-3 mb-xxl-0 me-3">Show</label
                                                        ><select class="d-inline-block dashInput sm-dropdown">
                                                            <option value="10">10</option>
                                                            <option value="25">25</option>
                                                            <option value="50">50</option>
                                                            <option value="100">100</option></select
                                                        ><label class="dashLabel d-inline-block mb-3 mb-xxl-0 ms-3"
                                                        >Entries</label
                                                        >
                                                    </div>
                                                    <div class="userInput mb-3">
                                                        <label class="dashLabel d-inline-block mb-3 mb-xxl-0"
                                                        >Filter By:</label
                                                        ><select class="d-inline-block dashInput">
                                                            <option value="">All</option>
                                                            <option value="true">Active</option>
                                                            <option value="false">Inactive</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="maain-tabble table-responsive">
                                            <table class="table table-bordered zero-configuration">
                                                <thead>
                                                    <tr>
                                                        <th>S.No.</th>
                                                        <th>Full Name</th>
                                                        <th>Email Address</th>
                                                        <th>Date Registered</th>
                                                        <th>Status</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody></tbody>
                                                <tbody>
                                                    <tr>
                                                        <td>1</td>
                                                        <td>Imtiaz</td>
                                                        <td>imtiaz@mailinator.com</td>
                                                        <td>04/02/2024</td>
                                                        <td><span class="status-tag green-tag">Active</span></td>
                                                        <td>
                                                            <div class="btn-group">
                                                                <button
                                                                    type="button"
                                                                    class="btn transparent-btn ellipsis-btn"
                                                                    data-bs-toggle="dropdown"
                                                                    aria-haspopup="true"
                                                                    aria-expanded="false"
                                                                >
                                                                    <i class="fa fa-ellipsis-v"></i>
                                                                </button>
                                                                <div class="dropdown-menu text-left custom-dropdown">
                                                                    <Link
                                                                        class="dropdown-item"
                                                                        to="/business-management/business-request-details"
                                                                    ><i class="far fa-eye"></i>View</Link
                                                                    >
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>2</td>
                                                        <td>John Wick</td>
                                                        <td>wick@mailinator.com</td>
                                                        <td>04/02/2024</td>
                                                        <td><span class="status-tag green-tag">Active</span></td>
                                                        <td>
                                                            <div class="btn-group">
                                                                <button
                                                                    type="button"
                                                                    class="btn transparent-btn ellipsis-btn"
                                                                    data-bs-toggle="dropdown"
                                                                    aria-haspopup="true"
                                                                    aria-expanded="false"
                                                                >
                                                                    <i class="fa fa-ellipsis-v"></i>
                                                                </button>
                                                                <div class="dropdown-menu text-left custom-dropdown">
                                                                    <a
                                                                        class="dropdown-item"
                                                                        href="/muvrrs/user-management/user-details/660ba999c724824540400309"
                                                                    ><i class="far fa-eye"></i>View</a
                                                                    >
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>3</td>
                                                        <td>Jaykay</td>
                                                        <td>jk@mailinator.com</td>
                                                        <td>03/18/2024</td>
                                                        <td><span class="status-tag green-tag">Active</span></td>
                                                        <td>
                                                            <div class="btn-group">
                                                                <button
                                                                    type="button"
                                                                    class="btn transparent-btn ellipsis-btn"
                                                                    data-bs-toggle="dropdown"
                                                                    aria-haspopup="true"
                                                                    aria-expanded="false"
                                                                >
                                                                    <i class="fa fa-ellipsis-v"></i>
                                                                </button>
                                                                <div class="dropdown-menu text-left custom-dropdown">
                                                                    <a
                                                                        class="dropdown-item"
                                                                        href="/muvrrs/user-management/user-details/65f825ef992784724510ad84"
                                                                    ><i class="far fa-eye"></i>View</a
                                                                    >
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>4</td>
                                                        <td>Weew</td>
                                                        <td>weew@yopmail.com</td>
                                                        <td>03/18/2024</td>
                                                        <td><span class="status-tag green-tag">Active</span></td>
                                                        <td>
                                                            <div class="btn-group">
                                                                <button
                                                                    type="button"
                                                                    class="btn transparent-btn ellipsis-btn"
                                                                    data-bs-toggle="dropdown"
                                                                    aria-haspopup="true"
                                                                    aria-expanded="false"
                                                                >
                                                                    <i class="fa fa-ellipsis-v"></i>
                                                                </button>
                                                                <div class="dropdown-menu text-left custom-dropdown">
                                                                    <a
                                                                        class="dropdown-item"
                                                                        href="/muvrrs/user-management/user-details/65f80778279a9c8b23c592db"
                                                                    ><i class="far fa-eye"></i>View</a
                                                                    >
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>5</td>
                                                        <td>yukuonetwo</td>
                                                        <td>yuku@mailinator.com</td>
                                                        <td>03/05/2024</td>
                                                        <td><span class="status-tag green-tag">Active</span></td>
                                                        <td>
                                                            <div class="btn-group">
                                                                <button
                                                                    type="button"
                                                                    class="btn transparent-btn ellipsis-btn"
                                                                    data-bs-toggle="dropdown"
                                                                    aria-haspopup="true"
                                                                    aria-expanded="false"
                                                                >
                                                                    <i class="fa fa-ellipsis-v"></i>
                                                                </button>
                                                                <div class="dropdown-menu text-left custom-dropdown">
                                                                    <a
                                                                        class="dropdown-item"
                                                                        href="/muvrrs/user-management/user-details/65e6e342492c11834cfbf35a"
                                                                    ><i class="far fa-eye"></i>View</a
                                                                    >
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>


                                                </tbody>
                                            </table>
                                        </div>
                                    </div> */}



                                    <Table
                                        key={"mover-management-signup-requests-table"}
                                        tableTitles={[
                                            "S.No.",
                                            "Business Name",
                                            "Email Address",
                                            "Date",
                                            "Status",
                                            "Action",
                                        ]}
                                        dateFilter
                                        showEntries
                                        // sortBy
                                        isLoading={isLoading}
                                        isFetching={isFetching}
                                        data={data?.data?.data?.docs}
                                        totalPages={data?.data?.data?.totalPages}
                                        totalDocs={data?.data?.data?.totalDocs}
                                        setPage={setPage}
                                        setSearchString={setSearchString}
                                        status={status}
                                        setStatus={setStatus}
                                        perPage={perPage}
                                        setPerPage={setPerPage}
                                        from={from}
                                        setFrom={setFrom}
                                        to={to}
                                        setTo={setTo}
                                    // status_options={[
                                    //   {
                                    //     label: "Pending",
                                    //     value: "pending",
                                    //   },
                                    //   {
                                    //     label: "Approved",
                                    //     value: "approved",
                                    //   },
                                    //   {
                                    //     label: "Rejected",
                                    //     value: "rejected",
                                    //   },
                                    // ]}
                                    >
                                        {!isFetching &&
                                            data?.data?.data?.docs?.map((item, index) => (
                                                <tr>
                                                    <td>{getSerialNumber(data?.data?.data, index)}</td>
                                                    <td>{item?.user?.companyName}</td>
                                                    <td>{item?.user?.email}</td>
                                                    <td>
                                                        {item?.createdAt &&
                                                            format_date(item?.createdAt, "MM/DD/YYYY")}
                                                    </td>
                                                    <td>{item?.status}</td>
                                                    <td>
                                                        <StatusHandler
                                                            key={`${item?._id}-status`}
                                                            view
                                                            // approval
                                                            item={item}
                                                            // isLoading={loadingStatus}
                                                            redirectUrl={`/business-management/business-request-details/${item?._id}`}
                                                        // onClick={() => {
                                                        //   if (item.requestApprovalStatus === "pending") {
                                                        //     Confirmation(
                                                        //       `Are You Sure You Want To Approve The Signup Request ?`,
                                                        //       "Yes",
                                                        //       () => console.log("Status Changed")
                                                        //     );
                                                        //   } else {
                                                        //     Reason(
                                                        //       "Are you sure you want to reject the signup request ?",
                                                        //       "Yes",
                                                        //       (val) => console.log("Val", val)
                                                        //     );
                                                        //   }
                                                        // }}
                                                        />
                                                    </td>
                                                </tr>
                                            ))}
                                    </Table>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    );
}

