import React from "react";
import {
  GoogleMap,
  useJsApiLoader,
  DirectionsRenderer,
} from "@react-google-maps/api";

function GMap({ originLat, originLng, destinationLat, destinationLng }) {
  const containerStyle = {
    width: "100%",
    height: "400px",
    borderRadius: "10px",
  };

  const zoom = 15;

  const origin = {
    lat: originLat && Number(originLat),
    lng: originLng && Number(originLng),
  };
  const destination = {
    lat: destinationLat && Number(destinationLat),
    lng: destinationLng && Number(destinationLng),
  };

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_API_KEY,
  });

  const [map, setMap] = React.useState(null);
  const [directions, setDirections] = React.useState(null);

  React.useEffect(() => {
    if (isLoaded) {
      const directionsService = new window.google.maps.DirectionsService();
      directionsService.route(
        {
          origin,
          destination,
          travelMode: window.google.maps.TravelMode.DRIVING,
        },
        (result, status) => {
          if (status === window.google.maps.DirectionsStatus.OK) {
            setDirections(result);
          }
        }
      );
    }
  }, [
    isLoaded,
    origin.lat == undefined ||
      origin.lng == undefined ||
      destination.lat == undefined ||
      destination.lng == undefined,
  ]);

  const onLoad = React.useCallback(function callback(map) {
    // This will fit the boundaries on the map
    // const bounds = new window.google.maps.LatLngBounds(center);
    // map.fitBounds(bounds);

    map.setZoom(zoom);
    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={origin}
      zoom={zoom}
      onLoad={onLoad}
      onUnmount={onUnmount}
    >
      {directions && (
        <DirectionsRenderer
          directions={directions}
          options={{
            markerOptions: { clickable: true },
          }}
        />
      )}
      {/* Child components, such as markers, info windows, etc. */}
      <></>
    </GoogleMap>
  ) : (
    <>
      <div className="iframeMap" style={{ paddingTop: "5.2em" }}>
        <p className="mb-0 cGray" style={{ textAlign: "center" }}>
          Loading Map...
        </p>
      </div>
    </>
  );
}

export default React.memo(GMap);
